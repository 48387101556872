<template>
  <div class="generate-outer-container">
    <!-- <div
      v-if="
        currentUser.user.generateBanner === -1 &&
        currentUser.user.role.toLowerCase() !== 'trialuser' &&
        isGenerateFullAccessBannerCheck() &&
        doUserHavePremiumSubscription
      "
      class="generate-banner"
    >
      {{ $t('generate.generateBanner') }}
      <button @click="onDismiss" class="dismiss-btn primary--text">
        {{ $t('generate.dismiss') }}
      </button>
    </div> -->
    <div class="generate-slides-container">
      <div
        class="gs-prompt-container"
        :class="{ 'right-panel-open': showRightPanel }"
      >
        <div class="page-heading">
          <v-tooltip bottom content-class="tooltip-content" fixed>
            <template v-slot:activator="{ on }">
              <div
                class="main"
                :style="{ width: `${mainHeadingWidth}%` }"
                v-on="on"
              >
                {{ $t('generate.generateHeading') }}
              </div>
            </template>
            <span>{{ $t('generate.generateHeading') }}</span>
          </v-tooltip>
          <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
        </div>
        <div
          class="gs-prompt"
          :class="{
            focus: autoGrow,
            blur: !isPromptBoxFocused,
          }"
        >
          <div
            class="fl-upldr-bx dynamic-box"
            ref="promptBoxRef"
            v-click-outside="clickOutsidePromptBox"
          >
            <div class="input">
              <v-textarea
                :value="prompMsg"
                clearable
                clear-icon="mdi-close"
                hide-details
                no-resize
                :auto-grow="autoGrow"
                :rows="1"
                class="text-input"
                :placeholder="$t('generate.generatePlaceHolder')"
                @focus="handlePromptTextFieldFocus"
                @blur="handlePromptTextFieldBlur($event)"
                :disabled="loading || !generationCompleted"
                @click:clear="handleClearPrompt()"
                data-pendo-id="generate-propmt"
                @input="handleInput"
              />
              <div class="promptBoxCTAs">
                <v-btn
                  class="addContext"
                  :disabled="clearContextDisabled"
                  @click="handleClearContext"
                  v-if="loading || loaderState || this.slides.length"
                >
                  {{ 'Clear context' }}
                </v-btn>
                <v-btn
                  class="addContext"
                  data-pendo-id="generate-add-context"
                  @click="openRightPanel('MagicFill', 'cta-btn')"
                  v-else
                >
                  <NewFeatureBadge
                    module-name="generate"
                    :feature-id="'add_context_id'"
                    :offsetX="'12.5'"
                    :offsetY="'0'"
                    :size="'small'"
                  >
                    {{ 'Add Context' }}
                  </NewFeatureBadge>
                </v-btn>
                <v-btn
                  v-if="!loading && generationCompleted"
                  elevation="2"
                  :disabled="disableGenerateBtn"
                  :loading="checkingUsage"
                  rounded
                  color="primary"
                  class="generate-btn"
                  @click="generate(true, true)"
                  data-pendo-id="generate-btn"
                >
                  {{ $t('slides.generate') }}
                </v-btn>
                <v-btn
                  v-else
                  elevation="2"
                  rounded
                  class="cancel-btn"
                  @click="cancelRequestClicked"
                >
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-menu
                  attach
                  bottom
                  right
                  :nudge-width="0"
                  :nudge-bottom="40"
                  :nudge-left="384"
                  offset-y
                  v-model="showLiveProTip"
                  :close-on-content-click="false"
                  :close-on-click="false"
                >
                  <LiveProTip
                    :is-menu-open="showLiveProTip"
                    :suggestions="liveSuggestions"
                    @close="showLiveProTip = false"
                    @generate="generateSuggestion"
                  />
                </v-menu>
              </div>
            </div>
            <div v-if="!disableActions" class="panel-items">
              <div
                :class="{
                  current: rightPanelComponent === 'AboutPrezentation',
                }"
                @click="
                  openRightPanel(
                    'AboutPrezentation',
                    'top-panel',
                    'editSlideCount',
                  )
                "
                v-if="slides.length > 1"
                data-pendo-id="generate-edit-slide-count-btn"
              >
                <v-icon size="18" color="#21A7E0" name="audience-icon">
                  mdi-card-multiple-outline
                </v-icon>
                <span>{{ $t('generate.editSlideCount') }}</span>
              </div>
              <div
                :class="{ current: rightPanelComponent === 'audience' }"
                @click="openRightPanel('audience', 'top-panel')"
                data-pendo-id="generate-audience"
              >
                <v-icon size="18" color="#21A7E0" name="audience-icon">
                  mdi-account-group
                </v-icon>
                <span>{{ $t('generate.changeAudience') }}</span>
              </div>
              <div
                :class="{ current: rightPanelComponent === 'template-menu' }"
                @click="openRightPanel('template-menu', 'top-panel')"
                data-pendo-id="generate-template"
              >
                <v-icon size="18" color="#21A7E0" name="template-icon">
                  mdi-compare
                </v-icon>
                <span>{{ $t('generate.switchTemplate') }}</span>
              </div>
              <div
                :class="{ current: rightPanelComponent === 'nodeCount' }"
                @click="openRightPanel('nodeCount', 'top-panel')"
                data-pendo-id="generate-node"
              >
                <v-icon size="18" color="#21A7E0" name="node-count-icon">
                  mdi-file-tree-outline
                </v-icon>
                <span>{{ $t('generate.editNodeCount') }}</span>
              </div>
              <div
                v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                :class="{ current: rightPanelComponent === 'replaceVisual' }"
                @click="openRightPanel('replaceVisual', 'top-panel')"
                data-pendo-id="generate-replace-visuals"
              >
                <v-icon size="18" color="#21A7E0" name="image-detection-icon">
                  {{ 'mdi-image-outline' }}
                </v-icon>
                <span>{{ $t('generate.replaceVisualsPromptAction') }}</span>
              </div>
              <div
                :class="{ current: rightPanelComponent === 'changeLayout' }"
                @click="openRightPanel('changeLayout', 'top-panel')"
                data-pendo-id="generate-change-layout"
              >
                <img src="/assets/img/generate/smartLayout-filled.svg" />
                <span>{{ $t('generate.changeLayout') }}</span>
              </div>
              <div
                :class="{
                  current: rightPanelComponent === 'MagicFill',
                }"
                @click="openRightPanel('MagicFill', 'top-panel')"
                data-pendo-id="generate-add-context"
              >
                <v-icon size="18" color="#21A7E0" name="audience-icon">
                  mdi-creation
                </v-icon>
                <span>{{ $t('generate.addContextPromtAction') }}</span>
              </div>
            </div>
            <div class="pro-tip-container" v-if="!disableActions">
              <ProTipContainer
                :animateProTip="animateProTip"
                :proTip="proTip"
                @handleProTip="handleProTip"
              ></ProTipContainer>
              <v-menu
                v-model="feedbackPopup"
                :close-on-content-click="false"
                :nudge-width="300"
                offset-y
                left
                content-class="generate-rating-popover"
              >
                <template v-slot:activator="{ attrs }">
                  <div
                    class="generate-rating-btn-container"
                    v-bind="attrs"
                    data-pendo-id="generate-rating-btn"
                  >
                    <div>
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          icon
                          @click.stop="
                            form.isLiked === null
                              ? handleRatingClick('like')
                              : () => {}
                          "
                          :class="{
                            bounce: animateLike,
                            'rating-disabled': form.isLiked !== null,
                          }"
                        >
                          <v-tooltip bottom v-model="likeTooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="
                                  (hover && form.isLiked === null) ||
                                  form.isLiked === true ||
                                  animateLike
                                    ? '#21a7e0'
                                    : '#000'
                                "
                                size="24"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{
                                  form.isLiked === true || animateLike
                                    ? 'mdi-thumb-up'
                                    : 'mdi-thumb-up-outline'
                                }}
                              </v-icon>
                            </template>
                            <span>{{
                              $t(
                                slides.length > 1
                                  ? 'generate.likeThisPresentation'
                                  : 'generate.likeThisSlide',
                              )
                            }}</span>
                          </v-tooltip>
                        </v-btn>
                      </v-hover>
                    </div>
                    <div>
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          icon
                          :class="{ 'rating-disabled': form.isLiked !== null }"
                          @click.stop="
                            form.isLiked === null
                              ? handleRatingClick('dislike')
                              : () => {}
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="
                                  (hover && form.isLiked === null) ||
                                  form.isLiked === false
                                    ? '#21a7e0'
                                    : '#000'
                                "
                                size="24"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{
                                  form.isLiked === false
                                    ? 'mdi-thumb-down'
                                    : 'mdi-thumb-down-outline'
                                }}
                              </v-icon>
                            </template>
                            <span>{{
                              $t(
                                slides.length > 1
                                  ? 'generate.dontLikeThisPresentation'
                                  : 'generate.dontLikeThisSlide',
                              )
                            }}</span>
                          </v-tooltip>
                        </v-btn>
                      </v-hover>
                    </div>
                  </div>
                </template>
                <v-card>
                  <v-btn
                    elevation="0"
                    icon
                    class="generate-rating-popover__close"
                    @click="closeFeedback"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <div v-if="feedbackSuccess" class="feedback-success">
                    <img src="/assets/img/generate/feedback_thanks.png" />
                    <span>{{ $t('generate.thankForFeedback') }}</span>
                  </div>
                  <template v-else>
                    <div class="generate-rating-popover__content">
                      <v-form ref="form" lazy-validation>
                        <div>
                          <label class="form__label">
                            {{ $t('generate.feedbackIsInvaluable') }}
                          </label>
                          <v-textarea
                            v-model="form.feedback"
                            :label="$t('generate.typeYourFeedback')"
                            solo
                            :rows="4"
                            class="form__textarea"
                            required
                          />
                        </div>
                      </v-form>
                    </div>

                    <v-card-actions class="form__action">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        style="text-transform: none !important"
                        rounded
                        class="primary-button"
                        width="100"
                        height="48"
                        @click="submitRatings"
                      >
                        {{ $t('common.submit') }}
                      </v-btn>
                    </v-card-actions>
                  </template>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
        <div
          class="promptDropdown"
          data-pendo-id="generate-suggestion"
          v-if="isPromptBoxFocused && (!prompMsg || prompMsg.length < 1)"
          :style="{
            width: `${promptDropdownWidth}px`,
            'box-shadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            top: `${promptDropdownHeight + 75}px`,
          }"
        >
          <div class="suggested">
            <div class="heading">
              <v-icon color="primary" medium>{{
                'mdi-message-check-outline'
              }}</v-icon>
              <div>{{ $t('generate.suggested') }}</div>
            </div>
            <div class="suggestedItemList" data-pendo-id="suggested-promt-list">
              <p
                v-for="(sugg, i) in promptDropdownSuggestions"
                class="suggestedItem"
                :id="`generate-suggested-${i}`"
                :key="i"
                @click="handlePromptDropdownItemSelect(sugg)"
              >
                {{ sugg }}
              </p>
            </div>
          </div>
          <div class="history">
            <div class="heading">
              <v-icon color="primary" medium>{{ 'mdi-history' }}</v-icon>
              <div>{{ $t('generate.history') }}</div>
            </div>
            <div
              class="historyItemList"
              v-if="promptDropdownHistory.length > 0"
            >
              <p
                v-for="(hist, i) in promptDropdownHistory"
                :id="`generate-history-${i}`"
                class="historyItem"
                :key="i"
                @click="handlePromptDropdownItemSelect(hist)"
              >
                {{ hist }}
              </p>
            </div>
            <div v-else class="noHistoryPresent">
              {{ $t('generate.recentPromptsDisplayedHere') }}
            </div>
          </div>
        </div>
        <div
          class="slide-container"
          :class="{ 'panel-open': !!rightPanelComponent }"
        >
          <div class="slides-list">
            <draggable
              v-if="slides.length !== 0"
              class="drag-area"
              :list="slides"
              group="slides"
              v-bind="dragOptions"
              draggable=".draggable"
              @end="dragEnd"
            >
              <template v-for="(slide, ind) in slides">
                <template>
                  <div
                    @click="selectedSlideID = slide.id"
                    :class="`draggable idea-${ind}-dragarea--auto`"
                    :key="ind"
                  >
                    <div>{{ ind + 1 }}</div>
                    <div
                      class="slide-small-thumbnail-container"
                      :class="{
                        selected: selectedSlideID === slide.id,
                      }"
                    >
                      <template
                        v-if="
                          slide.completed &&
                          !(
                            slide.slide_index === 0 &&
                            !generateLoader.isIdle() &&
                            (!changeReq ||
                              [
                                'audience_change',
                                'template_change',
                                'duration_change',
                              ].includes(changeReq.type))
                          )
                        "
                      >
                        <div class="slide-landscape" v-if="slide.error">
                          <img
                            :src="'/assets/img/generate/warning-line.png'"
                            alt="slide-pos-icon"
                          />
                        </div>
                        <img
                          :src="slide.landscape"
                          alt="image"
                          contain
                          class="slide-landscape"
                          v-else
                        />
                        <!-- <template v-slot:placeholder>
                            <v-img
                              :aspect-ratio="16 / 9"
                              src="/assets/img/slides/undraw_work_in_progress_uhmv.svg"
                              contain
                            />
                          </template> -->
                        <div class="slide-actions">
                          <v-icon> mdi-drag-vertical </v-icon>
                          <div>
                            <v-tooltip
                              top
                              max-width="250"
                              content-class="tooltip-content"
                              fixed
                              open-delay="500"
                              close-delay="300"
                              v-if="!slide.error"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  small
                                  icon
                                  :ripple="false"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="duplicateSlide(slide, ind)"
                                >
                                  <v-icon :size="rightPanelComponent ? 14 : 20">
                                    mdi-content-copy
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('common.duplicate') }}</span>
                            </v-tooltip>

                            <v-tooltip
                              top
                              max-width="250"
                              content-class="tooltip-content"
                              fixed
                              open-delay="500"
                              close-delay="300"
                              v-if="slides.length > 1"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  depressed
                                  small
                                  icon
                                  :ripple="false"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click.stop="deleteSlide(ind)"
                                >
                                  <v-icon :size="rightPanelComponent ? 14 : 20"
                                    >mdi-delete-outline</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>{{ $t('common.delete') }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </template>
                      <clip-loader
                        class="loading-spinner"
                        v-else
                        :color="'#21a7e0'"
                        :width="20"
                        :height="20"
                      />
                    </div>
                  </div>
                  <!-- <GenerateSlide
                    :slide="slide"
                    :key="slide.slide_index"
                    :class="{selected : slide.job_callbak_id === selected}"
                  ></GenerateSlide> -->
                </template>
              </template>
            </draggable>
            <template v-else>
              <div class="example-slide-mini">
                <span>1</span>
                <div v-if="error" class="example-mini-image">
                  <img
                    :src="'/assets/img/generate/warning-line.png'"
                    alt="slide-pos-icon"
                  />
                </div>
                <v-img
                  v-else-if="!loading"
                  :aspect-ratio="16 / 9"
                  :src="exampleLandscapeUrl"
                  contain
                  class="example-mini-image"
                />
                <div v-else class="example-mini-image">
                  <clip-loader
                    class="loading-spinner"
                    :color="'#21a7e0'"
                    :width="20"
                    :height="20"
                  />
                </div>
              </div>
            </template>
            <v-btn
              :disabled="disableActions"
              :height="rightPanelComponent ? 30 : 40"
              :width="rightPanelComponent ? 30 : 40"
              depressed
              elevation="2"
              fab
              @click="openRightPanel('AddSlide')"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </div>
          <div class="slide-content">
            <div class="landscape-slide">
              <div
                v-if="error || (selectedSlide && selectedSlide.error)"
                class="error-img-container"
              >
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="errorImg"
                  alt="image"
                  contain
                />
                <div class="landscape-actions" :class="{ 'deck-error': error }">
                  <v-btn
                    v-if="
                      selectedSlide &&
                      selectedSlide.error === 'TOXICERR' &&
                      selectedSlide.addNewSlide
                    "
                    elevation="2"
                    rounded
                    color="primary"
                    class="generate-btn"
                    @click="tryAddSlideAgain"
                  >
                    {{ $t('common.tryAgain') }}
                  </v-btn>
                  <v-btn
                    v-else-if="selectedSlide && selectedSlide.error"
                    elevation="2"
                    rounded
                    color="primary"
                    class="generate-btn"
                    @click="openRightPanel('MagicFill')"
                  >
                    {{ $t('generate.addSlideContext') }}
                  </v-btn>
                  <v-btn
                    v-if="
                      (error && lastSuccessfulDeck) ||
                      (selectedSlide &&
                        selectedSlide.error &&
                        selectedSlide.lastSuccessfulSlide)
                    "
                    elevation="2"
                    rounded
                    class="cancel-btn"
                    @click="undo()"
                  >
                    {{ $t('generate.undo') }}
                  </v-btn>
                </div>
              </div>
              <VideoLoader
                v-else-if="!generateLoader.isIdle()"
                v-model="generateLoader"
              />
              <div
                v-else-if="selectedSlide && selectedSlide.completed"
                :data-pendo-id="imageRendered ? 'generated-slide' : ''"
                id="replace-main-image"
              >
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="selectedSlide.landscape"
                  alt="image"
                  contain
                  @load="onImageRender()"
                >
                  <!-- <template v-slot:placeholder>
                    <v-img
                      :aspect-ratio="16 / 9"
                      src="/assets/img/slides/undraw_work_in_progress_uhmv.svg"
                      contain
                    />
                  </template> -->
                </v-img>
              </div>
              <VideoLoader
                v-else-if="selectedSlide && !selectedSlide.completed"
                v-model="generateSmartToolsLoader"
              />
              <div v-else>
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="exampleLandscapeUrl"
                  alt="image"
                  contain
                >
                </v-img>
              </div>
            </div>
            <div class="gs-privacy">
              <div class="gs-privacy-sub">
                {{ $t('overnightPresentations.warningAstrid') }}
              </div>
              <div>
                {{ $t('overnightPresentations.confidentiality') }}
                <a
                  href="https://www.prezent.ai/security"
                  target="_blank"
                  class="primary--text"
                  >{{ $t('fingerprint.learnMore') }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="quick-access-toolbar">
          <!--
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <MagicFillSwitch
                  :disabled="disableActions"
                  @change="magicFillChanged"
                ></MagicFillSwitch>
              </div>
            </template>
            <span>Magic Fill</span>
          </v-tooltip>
          !-->
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
              v-if="!removingFromLibrary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    id="add-to-library"
                    small
                    icon
                    :ripple="false"
                    :color="hover || isAddedToLibrary ? '#21A7E0' : iconColor"
                    :style="
                      !generationCompleted || !deckValid
                        ? {
                            pointerEvents: 'none',
                            cursor: 'not-allowed',
                          }
                        : {}
                    "
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddToSlideLibrary"
                  >
                    <v-icon
                      name="add-to-library-icon"
                      data-pendo-id="generate-add-to-library"
                    >
                      {{
                        isAddedToLibrary
                          ? slides.length > 1
                            ? 'mdi-folder'
                            : 'mdi-folder-table'
                          : slides.length > 1
                          ? 'mdi-folder-outline'
                          : 'mdi-folder-table-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t(addToLibraryTooltip) }}</span>
            </v-tooltip>
            <v-progress-circular
              indeterminate
              :color="'#21A7E0'"
              :size="25"
              v-if="isAddedToLibrary && removingFromLibrary"
            />
          </div>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
              v-if="!removingAsFavorite"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    id="favorite"
                    small
                    icon
                    :ripple="false"
                    :color="hover || isAddedAsFavorite ? '#21A7E0' : iconColor"
                    :style="
                      !generationCompleted || !deckValid
                        ? {
                            pointerEvents: 'none',
                            cursor: 'not-allowed',
                          }
                        : {}
                    "
                    v-bind="attrs"
                    v-on="on"
                    @click="handleAddToFavorites()"
                  >
                    <v-icon
                      name="favorite-icon"
                      data-pendo-id="generate-add-to-favourite"
                    >
                      {{
                        isAddedAsFavorite ? 'mdi-heart' : 'mdi-heart-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t(addToFavoriteTooltip) }}</span>
            </v-tooltip>
            <v-progress-circular
              indeterminate
              :color="'#21A7E0'"
              :size="25"
              v-if="isAddedAsFavorite && removingAsFavorite"
            />
          </div>
          <v-tooltip
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  id="download"
                  icon
                  :ripple="false"
                  :color="
                    (hover && '#21A7E0') ||
                    (!generationCompleted ||
                    !deckValid ||
                    getGenerateSlideDownloadLimitExceeded
                      ? '#757575'
                      : '#000')
                  "
                  :style="
                    !generationCompleted ||
                    !deckValid ||
                    getGenerateSlideDownloadLimitExceeded
                      ? {
                          pointerEvents: 'none',
                          cursor: 'not-allowed',
                        }
                      : {}
                  "
                  v-bind="attrs"
                  v-on="on"
                  @click="handleDownload()"
                >
                  <v-icon
                    name="download-icon"
                    data-pendo-id="generate-download"
                  >
                    {{ downloadIcon }}
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
          </v-tooltip>
          <!-- <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  small
                  :class="`ma-0 rounded-circle`"
                  icon
                  color="#000"
                  :ripple="false"
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleContext"
                >
                  <v-icon name="context-icon">
                    {{ isContextPromptSwitchOn ? contextIcon : contextOffIcon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                isContextPromptSwitchOn
                  ? 'Contextual Prompt Enabled'
                  : 'Contextual Prompt Disabled'
              }}</span>
            </v-tooltip>
            <v-menu
              :value="displayContextPrompt"
              :attach="true"
              absolute
              offset-y
              :position-x="-270"
              :position-y="40"
              :nudge-width="460"
              :close-on-click="true"
              :close-on-content-click="false"
              @input="onContextPromptClose"
              content-class="context-prompt-menu"
            >
              <div v-if="isContextPromptSwitchOn && currentPrompt">
                <ContextualPrompt
                  :prompt="currentPrompt"
                  :primaryTextOverride="primaryBtnText"
                  :primaryDisabled="primaryDisabled"
                  @close="onContextPromptClose"
                  @secondaryBtnClick="contextSecondaryBtnClick"
                  @primaryBtnClick="contextPrimaryBtnClick"
                >
                  <template #title>{{ promptTitle }}</template>
                  <template #body>
                    <template
                      v-if="currentPrompt.type === 'NO_AUDIENCE_DETECTED'"
                    >
                      You can create a slide tailored to the preferences of your
                      primary audience! Mention their full name in your prompt
                      and we will use their fingerprint preferences.
                      <div>
                        Here is an example:
                        <div class="example">
                          "Create an agenda slide for
                          <strong>John Doe</strong>"
                        </div>
                      </div>
                      <div class="subtext">
                        You can also change the slide by selecting a different
                        audience in the toolbar
                      </div>
                    </template>
                    <template
                      v-else-if="currentPrompt.type === 'NODE_NOT_DETECTED'"
                    >
                      You can customize your slide based on the number of key
                      messages you want to present. Simply add the number of
                      nodes to your prompt.
                      <div>
                        Here is an example:
                        <div class="example">
                          "<strong>Three</strong> Strategies for Leadership
                          Development"
                        </div>
                      </div>
                      <div class="subtext">
                        You can also change the node count from the toolbar.
                      </div>
                    </template>
                    <template
                      v-else-if="currentPrompt.type === 'NO_TEMPLATE_DETECTED'"
                    >
                      Just add the template name to your prompt.

                      <div>
                        Here is an example:
                        <div class="example">
                          "Create a Vision slide with a telescope in
                          <strong>Acme Corp 2023</strong> template"
                        </div>
                      </div>
                      <div class="subtext">
                        You can also change your slide template from the
                        toolbar.
                      </div>
                    </template>
                    <template v-else>
                      {{ promptBody }}
                    </template>
                  </template>
                </ContextualPrompt>
              </div>
            </v-menu>
          </div> -->
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    id="tutorials"
                    small
                    icon
                    :ripple="false"
                    :color="showTutorialMenu || hover ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTutorialMenu = !showTutorialMenu"
                  >
                    <v-icon
                      name="tutorials-icon"
                      data-pendo-id="generate-tutorials"
                    >
                      mdi-television-play
                    </v-icon>
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t('generate.tutorials') }}</span>
            </v-tooltip>
            <v-menu
              v-model="showTutorialMenu"
              :attach="true"
              absolute
              offset-y
              :position-x="-325"
              :position-y="40"
              :nudge-width="460"
              :close-on-click="true"
              :close-on-content-click="false"
              content-class="tutorial-menu"
            >
              <TutorialMenu :category="'Auto Generator'"></TutorialMenu>
            </v-menu>
          </div>
          <div v-if="getAGLivePromptAccess">
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    id="prompt-suggestions-toggle-btn"
                    :ripple="false"
                    :color="hover ? '#21A7E0' : '#000'"
                    v-bind="attrs"
                    data-pendo-id="generate-suggested-live-prompts"
                    v-on="on"
                    @click="toggleLivePrompt"
                  >
                    <img
                      :class="{ 'blue-bg': hover }"
                      :src="
                        getAGLiveSuggestionEnabled
                          ? `/assets/img/generate/speaker_notes${
                              hover ? '_hover' : ''
                            }.svg`
                          : `/assets/img/generate/speaker_notes_off${
                              hover ? '_hover' : ''
                            }.svg`
                      "
                    />
                  </v-btn>
                </v-hover>
              </template>
              <span
                >Prompt suggestions
                {{ getAGLiveSuggestionEnabled ? 'on' : 'off' }}</span
              >
            </v-tooltip>
          </div>
          <div>
            <v-tooltip
              bottom
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    depressed
                    small
                    icon
                    :ripple="false"
                    color="#000"
                    v-bind="attrs"
                    v-on="on"
                    id="prompt-examples"
                    data-pendo-id="generate-prompt-examples"
                    @click="openRightPanel('promptExamples')"
                  >
                    <img
                      v-if="rightPanelComponent === 'promptExamples' || hover"
                      src="/assets/img/generate/quick_reference_blue.svg"
                    />
                    <img
                      v-else
                      src="/assets/img/generate/quick_reference_black.svg"
                    />
                  </v-btn>
                </v-hover>
              </template>
              <span>{{ $t('generate.promptExamples') }}</span>
            </v-tooltip>
          </div>
          <v-tooltip
            v-if="!showRightPanel"
            bottom
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openSmartTools('onClick')"
                  :color="
                    (hover && '#21A7E0') ||
                    (disableActions ? '#757575' : '#000')
                  "
                  :style="
                    disableActions
                      ? {
                          pointerEvents: 'none',
                          cursor: 'not-allowed',
                        }
                      : {}
                  "
                  v-bind="attrs"
                  v-on="on"
                  data-pendo-id="generate-smart-tools"
                >
                  <v-icon name="right-panel-icon">
                    {{ smartToolIcon }}
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.smartTools') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div
        class="right-panel"
        :class="{ fade: hideRightPanel, hide: !showRightPanel }"
      >
        <PromptExamples
          v-if="rightPanelComponent === 'promptExamples'"
          :openContext="
            ['long_prompt', 'unclear_intent'].includes(proTip.toLowerCase())
          "
        />
        <AddNewSlide
          v-if="rightPanelComponent === 'AddSlide'"
          :slideCount="slides.length"
          :currentPos="selectedSlidePos"
          :isFromSmartTools="isFromSmartTools"
          @change="handleConfirmation"
        />
        <AboutPrezentation
          v-if="rightPanelComponent === 'AboutPrezentation'"
          :description="description"
          :slideCount="slides.length"
          @change="handleConfirmation"
          :isFromTopPanel="isFromTopPanel"
          @changeDescription="
            (desc) => {
              description = desc;
            }
          "
        />
        <MagicFill
          v-if="rightPanelComponent === 'MagicFill'"
          @change="handleConfirmation"
          :selectedSlide="selectedSlide"
        />
        <replace-visual
          v-if="rightPanelComponent === 'replaceVisual'"
          :slide="selectedSlide"
          :extractedImages="extractedImages"
          ref="replaceVisual"
          @error="replaceImageError"
          @changePanel="
            (e) => {
              openRightPanel(e);
            }
          "
          @change="handleConfirmation"
          @toggleReplaceImage="onImageReplaced"
          @replaceStart="startReplaceVisuals"
        />
        <TemplateMenu
          v-if="rightPanelComponent === 'template-menu'"
          :template="template"
          class="template-selector-active"
          :isCloseBtn="false"
          :origin="'generate'"
          @change="handleConfirmation"
        />
        <AudienceMenu
          v-if="rightPanelComponent === 'audience'"
          :audience="audience"
          @change="handleConfirmation"
        />
        <ChangeLayout
          v-if="rightPanelComponent === 'changeLayout'"
          :relatedSlides="selectedSlide ? selectedSlide.relatedSlides : []"
          @change="handleConfirmation"
        />
        <NodeCount
          v-if="rightPanelComponent === 'nodeCount'"
          :nodeCount="selectedSlide.slide_node_count"
          :siblings="selectedSlide.siblings"
          :slideType="selectedSlide.slide_type"
          @change="handleConfirmation"
        />
      </div>
      <div
        v-if="isSmartToolsOpen"
        class="smart-tool-switch"
        data-pendo-id="generate-smart-tool-switch"
      >
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-hover v-slot="{ hover }">
              <v-btn
                depressed
                small
                icon
                :ripple="false"
                @click="closeSmartTool"
                :color="hover ? '#21A7E0' : '#000'"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon name="close-icon"> mdi-close </v-icon>
              </v-btn>
            </v-hover>
          </template>
          <span>{{
            slides.length ? $t('generate.closeSmartTools') : $t('common.close')
          }}</span>
        </v-tooltip>
        <template v-if="!disableActions">
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('audience')"
                  :color="
                    rightPanelComponent === 'audience' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!selectedSlide || !!selectedSlide.error"
                >
                  <v-icon name="audience-icon">
                    mdi-account-group-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('overnightPresentations.audience1') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('template-menu')"
                  :color="
                    rightPanelComponent === 'template-menu' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!selectedSlide || !!selectedSlide.error"
                >
                  <v-icon name="template-icon"> mdi-compare </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('overnightPresentations.template') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('nodeCount')"
                  :color="
                    rightPanelComponent === 'nodeCount' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!selectedSlide || !!selectedSlide.error"
                >
                  <v-icon name="node-count-icon">
                    mdi-file-tree-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.nodeCount') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('changeLayout')"
                  :color="
                    rightPanelComponent === 'changeLayout' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  data-pendo-id="generate-smart-layout"
                  :disabled="!selectedSlide || !!selectedSlide.error"
                >
                  <img
                    v-if="!selectedSlide || !!selectedSlide.error"
                    src="/assets/img/generate/smartLayout-disabled.svg"
                  />
                  <img
                    v-else-if="rightPanelComponent === 'changeLayout' || hover"
                    src="/assets/img/generate/smartLayout-filled.svg"
                  />
                  <img v-else src="/assets/img/generate/smartLayout.svg" />
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.smartLayout') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  v-if="allowReplaceImageOverall && allowReplaceImageTabs"
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('replaceVisual')"
                  :color="
                    rightPanelComponent === 'replaceVisual' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!selectedSlide || !!selectedSlide.error"
                >
                  <v-icon name="replace-visual-icon">
                    mdi-image-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.replaceVisuals') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('MagicFill', 'smart-tools')"
                  :color="
                    rightPanelComponent === 'MagicFill' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  data-pendo-id="generate-magic-fill"
                >
                  <v-icon name="magic-fill-icon"> mdi-creation </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.addContext') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('AddSlide', 'smart-tools')"
                  :color="
                    rightPanelComponent === 'AddSlide' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :style="{ transform: 'scaleY(-1)' }"
                >
                  <v-icon
                    name="add-slide-icon"
                    data-pendo-id="generate-add-slide"
                  >
                    mdi-card-plus-outline
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.addNewSlide') }}</span>
          </v-tooltip>
          <v-tooltip
            left
            max-width="250"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
            v-if="slides.length > 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  depressed
                  small
                  icon
                  :ripple="false"
                  @click="openRightPanel('AboutPrezentation')"
                  :color="
                    rightPanelComponent === 'AboutPrezentation' || hover
                      ? '#21A7E0'
                      : '#000'
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon name="about-prezentation-icon">
                    mdi-playlist-edit
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>{{ $t('generate.aboutYourPrezentation') }}</span>
          </v-tooltip>
        </template>
        <!-- <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              color="#000"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon name="best-practice-icon">
                mdi-lightbulb-on-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Best Practices</span>
        </v-tooltip>
        <div class="divider"></div>
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              color="#000"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon name="undo-icon"> mdi-arrow-u-left-top </v-icon>
            </v-btn>
          </template>
          <span>Undo</span>
        </v-tooltip>
        <v-tooltip
          left
          max-width="250"
          content-class="tooltip-content"
          fixed
          open-delay="500"
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              color="#000"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon name="redo-icon"> mdi-arrow-u-right-top </v-icon>
            </v-btn>
          </template>
          <span>Redo</span>
        </v-tooltip> -->
      </div>
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="
        replaceVisualCancel ? replaceVisualCancel : $t('common.cancel')
      "
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
    <!-- <LibraryConfirmationPopup
      v-if="isAddedToLibraryDownload"
      :goToLibrary="goToLibrary"
      :message="
        slides.length > 1
          ? $t('premiumFeature.addedToPrezLibrary')
          : $t('premiumFeature.addedToSlideLibrary')
      "
      :btnText="$t('premiumFeature.openInLibrary')"
      :cancel="closePopup"
    ></LibraryConfirmationPopup> -->
  </div>
</template>
<script>
import moment from 'moment';
import {
  mdiDownloadOutline,
  mdiMessageStar,
  mdiSquareEditOutline,
  mdiMessageStarOutline,
} from '@mdi/js';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapState } from 'vuex';
import { graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
// import snakeCase from 'lodash/snakeCase';
import Vue from 'vue';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import EventBus from '../../common/event-bus';
import ReplaceVisual from './ReplaceVisual/ReplaceVisual.vue';
import MagicFill from './MagicFill.vue';
import AddNewSlide from './AddNewSlide.vue';
import AboutPrezentation from './AboutPrezentation.vue';
// import GenerateLoader from './GenerateLoader.vue';
import TemplateMenu from '../NewPrezentation/TemplateMenu.vue';
import TutorialMenu from './TutorialMenu.vue';
import AudienceMenu from './AudienceMenu.vue';
import ChangeLayout from './ChangeLayout.vue';
import NodeCount from './NodeCount.vue';
import ProTipContainer from './ProTipContainer.vue';
// import ContextualPrompt from './ContextualPrompt.vue';
import { getRelatedSlides } from '../../../graphql/queries';
import { getFileURL } from '@/utils/calyrex';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
// import { getFileURLDownload } from '@/utils/calyrex-test';

import {
  // getSlideByID,
  generateDownload,
  generateSlideRequestLog,
  generateSlideRequest,
  checkGSStatus,
  cancelGSRequest,
  getSlideDataForDetailView,
  generateSlideCompleteLog,
  generateSlideUpdateLog,
  generateSlidePostCompleteLog,
  getSlideDetail,
  errorRequestLog,
  deleteSlide,
  deleteFavorite,
  getPromptDropdownHistoryOnLoad,
  sendFPReminder,
  addToLibrary,
  createSlide,
  createFavorite,
  addPrezentationAsFavorite,
  removePrezentationAsFavorite,
  removeFromLibrary,
  getFeatureUsage,
  postFeatureUsage,
  checkBulkStatus,
  uploadChunks,
  uploadOPFile,
  retryOperation,
  // retryOperation,
  // getUserCompanyInfo,
} from '../../../utils/api-helper';
import PromptExamples from './PromptExamples.vue';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
// import { downloadFileWithCustomName } from '../../../utils/common';
import GenerateActionModal from './GenerateActionModal.vue';
// import usersApi from '../../../API/users-api';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
// import LibraryConfirmationPopup from '../../common/LibraryConfimationPopup.vue';
import ShareFreeTrailNew from '../../navbar/ShareFreeTrailNew.vue';
import audienceApi from '../../../API/audience-api';
import { calyrexConfig, miscConfig } from '../../../runtimeConfig';
import { trackGenerateEvents } from '../../common/Analytics/GenerateEvents';
import {
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_DIRECT,
  TD_INSTRUCTIONS,
  TD_ISDOWNLOAD,
  TD_NODECOUNT,
  TD_REPLACE_IMAGE_SOURCE,
  TD_PLACEMENT,
  TD_SLIDE_NAME,
  TD_NUMBEROFSLIDES,
  TD_SMART_TOOLS,
  TD_ABOVE_THE_GENERATED_SLIDE,
  TD_ISFAVORITED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_ICON_ON_TOP,
  TD_RATING,
  TD_CTA_BUTTON,
  NA,
  TD_ICON_AT_THE_TOP,
  TD_TIME_SPENT_IN_SECS,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { GraphQLService } from '../../../services/GraphQLService';
import { triggerReplaceImage } from '../../../utils/replace-image-api-helper';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import LiveProTip from './LiveProTip.vue';
import DayZeroPopup from '../../common/DayZeroPopup.vue';
import VideoLoader from '../../common/VideoLoader.vue';
import { Loader } from '../../../utils/loader-helper';

export default {
  name: 'GenarateSlidesContainer',
  components: {
    // MagicFillSwitch,
    MagicFill,
    // GenerateLoader,
    VideoLoader,
    ReplaceVisual,
    TemplateMenu,
    AudienceMenu,
    ChangeLayout,
    NodeCount,
    // ContextualPrompt,
    ConfirmationDialog,
    AboutPrezentation,
    AddNewSlide,
    draggable,
    TutorialMenu,
    ClipLoader,
    PromptExamples,
    // LibraryConfirmationPopup,
    NewFeatureBadge,
    ProTipContainer,
    LiveProTip,
  },
  data() {
    return {
      checkingUsage: false,
      imageRendered: false,
      slides: [],
      selectedSlideID: null,
      replaceVisualCancel: '',
      dragOptions: {
        animation: 900,
        ghostClass: 'ghost-list',
      },
      feedbackPopup: false,
      feedbackSuccess: false,
      // isAddedToLibraryDownload: false,
      form: {
        isLiked: null,
        feedback: '',
      },
      executionTime: {},
      generateType: '',
      extractedImages: [],
      audience: null,
      template: null,
      showTutorialMenu: false,
      uuid: null,
      downloadIcon: mdiDownloadOutline,
      prezOrSlideId: null,
      contextIcon: mdiMessageStar,
      contextOffIcon: mdiMessageStarOutline,
      smartToolIcon: mdiSquareEditOutline,
      prompMsg: '',
      isContextPromptSwitchOn: true,
      showContextPrompt: false,
      forceTemplate: false,
      forceUser: false,
      changeReq: null,
      showConfirmation: false,
      dialogPrimary: '',
      dialogText: '',
      dialogTitle: '',
      error: null,
      filename: '',
      modifiedFileName: '',
      description: '',
      isFromTopPanel: false,
      isFromSmartTools: false,
      contextPrompts: [
        {
          type: 'NO_AUDIENCE_DETECTED',
          primaryCTA: 'Add audience',
          asset: '/assets/img/generate/addAudience.gif',
        },
        {
          type: 'AUDIENCE_NOT_COMPLETED_FINGERPRINT',
          secondaryCTA: 'Take fingerprint assessment',
          primaryCTA: 'Remind audience',
          asset: '/assets/img/generate/remindAudience.gif',
        },
        {
          type: 'NO_ACCESS_TO_AUDIENCE',
          primaryCTA: 'Request access',
          secondaryCTA: 'Take fingerprint assessment',
          asset: '/assets/img/generate/lockedAudience.gif',
        },
        {
          type: 'AUDIENCE_NOT_AVAILABLE',
          primaryCTA: 'Add audience',
          secondaryCTA: 'Share free trial',
          asset: '/assets/img/generate/missingAudience.gif',
        },
        {
          type: 'NODE_NOT_DETECTED',
          primaryCTA: 'Change node count',
          asset: '/assets/img/generate/noNodeCount.gif',
        },
        {
          type: 'NO_TEMPLATE_DETECTED',
          primaryCTA: 'Change template',
          asset: '/assets/img/generate/noTemplate.gif',
        },
        {
          type: 'NO_MULTIPLE_SLIDES',
          asset: '/assets/img/generate/multipleSlides.gif',
        },
      ],
      exampleSlides: [
        {
          sid: '0001',
          category: 'genprevexamp',
          placeholder:
            'Create a vision slide for a company focused on sustainability',
        },
        {
          sid: '0002',
          category: 'genprevexamp',
          placeholder:
            'Create a 5-point slide for boosting inclusiveness and diversity in the organization',
        },
        {
          sid: '0003',
          category: 'genprevexamp',
          placeholder:
            'Create a 2030 mission slide for a company focused on environment',
        },
      ],
      exampleIndex: 0,
      exampleLandscape: null,
      generateOperation: {
        loading: false,
        success: false,
      },
      isAddedToLibrary: false,
      isAddedAsFavorite: false,
      addedToLibraryData: null,
      removingFromLibrary: false,
      removingAsFavorite: false,
      isPromptBoxFocused: false,
      promptDropdownWidth: 0,
      promptDropdownHeight: 0,
      promptDropdownSuggestions: [
        'Create a 4 slide presentation on diversity in a tech company. Add an image of people. The first slide should have a concise title. The second slide should cover points on why diversity is an important initiative. For the third slide, share ideas on improving diversity',
        'Create a vision slide with 4 points for electronic vehicle manufacturer focused on sustainability',
        'Create a five-slide presentation about brand annual performance review. The first slide should be a title slide with an image of a doctor. The second slide should include an executive summary. The third slide should summarize the performance of the brand. The fourth should include the challenges faced last year. The final slide should list the next steps',
      ],
      promptDropdownHistory: [],
      imageDetected: '',
      pollingInterval: [],
      primaryBtnText: '',
      primaryDisabled: false,
      loading: false,
      // disableActions: true,
      proTip: 'none_of_the_above',
      animateProTip: false,
      animateLike: false,
      likeTooltip: false,
      mainHeadingWidth: 0,
      generatingNewSlide: false,
      slideCallbackIds: [],
      statusApiInProgress: false,
      lastSuccessfulDeck: null,
      replacingImage: false,
      showLiveProTip: false,
      liveSuggestions: [],
      suggestionStatus: null,
      startTime: '',
      generateLoader: new Loader('generate'),
      generateSmartToolsLoader: new Loader('generateSmartTools', 1),
    };
  },
  watch: {
    '$route.query': {
      async handler(newVal) {
        if (
          newVal.prompt &&
          newVal.prompt !== this.prompMsg &&
          !this.slides.length &&
          !this.loading
        ) {
          this.prompMsg = newVal.prompt;
        }
      },
      deep: true,
      immediate: true,
    },
    selectedSlide(val) {
      if (
        this.rightPanelComponent === 'promptExamples' ||
        this.rightPanelComponent === 'MagicFill'
      ) {
        return;
      }
      if (
        !val ||
        (!val.completed && this.rightPanelComponent !== 'replaceVisual')
      ) {
        this.closeSmartTool();
      } else if (
        val.error &&
        this.rightPanelComponent &&
        !['MagicFill', 'AboutPrezentation', 'AddSlide'].includes(
          this.rightPanelComponent,
        )
      ) {
        this.closeSmartTool();
      }
    },
    loaderState(val) {
      if (val) {
        this.generateLoader.nextState(1);
      } else if (this.slides.length) {
        this.generateLoader.nextState(2);
      }
    },
    disableActions(val) {
      if (!val) {
        this.animateLike = true;
        this.likeTooltip = true;
        setTimeout(() => {
          this.animateLike = false;
          this.likeTooltip = false;
        }, 3000);
        // this.disableActions = false;
        this.openSmartTools();

        // Opening smart layout on every successful generation or regeneration
        if (this.generationCompleted && !this.loaderState) {
          this.setRightPanelComponent('changeLayout');
        }

        // if (!this.deckValid) {
        //   this.reportErrorRequest(
        //     this.slides.length === 1
        //       ? this.slides[0].job_callbak_id
        //       : this.uuid,
        //     'Prompt_Error',
        //   );
        // } else {
        this.postLog(this.changeReq);
        // }
      }
    },
    slideCallbackIds() {
      if (!this.statusApiInProgress && this.slideCallbackIds.length) {
        this.statusApiInProgress = true;
        this.startBulkStatus();
      }
    },
  },
  computed: {
    ...mapState('generateSlides', [
      'isSmartToolsOpen',
      'showRightPanel',
      'rightPanelComponent',
      'promptLoadIndex',
      'promptText',
    ]),
    ...mapGetters('generateSlides', ['getDeckContext']),
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'themes',
      'limitsInfoObj',
    ]),
    ...mapGetters('users', [
      'getGenerateSlideDownloadLimitExceeded',
      'isGenerateFullAccess',
      'allowAgDayZeroPopup',
      'getAGLiveSuggestionEnabled',
      'getAGLivePromptAccess',
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      // 'doUserHavePremiumSubscription',
    ]),
    autoGrow() {
      return !!(
        this.isPromptBoxFocused &&
        this.prompMsg &&
        this.prompMsg.length > 0
      );
    },
    disableGenerateBtn() {
      return (
        this.showLiveProTip ||
        !this.prompMsg ||
        this.prompMsg.trim().length === 0 ||
        this.loading ||
        (!!this.slides.length &&
          ['MagicFill', 'AddSlide'].includes(this.rightPanelComponent)) ||
        (!this.slides.length &&
          !!this.getDeckContext.links.length &&
          !this.getDeckContext.linkAccess)
      );
    },
    errorImg() {
      if (
        this.error === 'TOXICERR' ||
        (this.selectedSlide && this.selectedSlide.error === 'TOXICERR')
      ) {
        return '/assets/img/generate/toxic_error.svg';
      }
      if (
        this.error === 'PROMPTERR' ||
        (this.selectedSlide && this.selectedSlide.error === 'TOXICERR')
      ) {
        return '/assets/img/generate/prompt_error.svg';
      }
      return '/assets/img/generate/prompt_error.svg';
    },
    disableActions() {
      if (this.loading || this.slides.length === 0) {
        return true;
      }
      if (
        this.generationCompleted &&
        (!this.changeReq ||
          ['audience_change', 'duration_change', 'template_change'].includes(
            this.changeReq.type,
          ))
      ) {
        return false;
      }
      if (
        this.changeReq &&
        [
          'slide_change',
          'node_change',
          'context_change',
          'replaceVisuals',
          'add_new_slide',
          'clear_context',
        ].includes(this.changeReq.type)
      ) {
        return false;
      }
      return true;
    },
    loaderState() {
      if (this.loading || (this.disableActions && !this.coverSlideGenerated)) {
        return true;
      }
      return false;
    },
    iconColor() {
      return !this.generationCompleted || !this.deckValid ? '#757575' : '#000';
    },
    hideRightPanel() {
      if (
        this.rightPanelComponent === 'promptExamples' ||
        (this.rightPanelComponent === 'MagicFill' && !this.selectedSlide)
      ) {
        return false;
      }
      if (
        this.selectedSlide &&
        this.selectedSlide.completed &&
        !this.selectedSlide.error
      ) {
        return false;
      }
      if (
        this.selectedSlide &&
        this.selectedSlide.completed &&
        this.selectedSlide.error &&
        ['MagicFill', 'AboutPrezentation', 'AddSlide'].includes(
          this.rightPanelComponent,
        )
      ) {
        return false;
      }
      return true;
    },
    deckValid() {
      return (
        this.slides.length && this.slides.some((i) => i.completed && !i.error)
      );
    },
    addToLibraryTooltip() {
      if (this.isAddedToLibrary) {
        return this.addedToLibraryData.type === 'slide'
          ? 'generateToolBar.remove_from_slide_library'
          : 'generateToolBar.remove_from_prez_exchange';
      }
      return this.slides.length > 1
        ? 'generateToolBar.add_to_prez_exchange'
        : 'generateToolBar.add_to_slide_library';
    },
    addToFavoriteTooltip() {
      if (this.isAddedAsFavorite) {
        return this.addedToLibraryData.type === 'slide'
          ? 'premiumFeature.removeSlideFromFavourite'
          : 'premiumFeature.removePrezentationFromFavourite';
      }
      return this.slides.length > 1
        ? 'premiumFeature.addPrezentationToFavourites'
        : 'premiumFeature.addSlideToFavourites';
    },
    exampleLandscapeUrl() {
      return this.currentUser.user.visualPreference &&
        this.currentUser.user.visualPreference[0].toLowerCase() === 'vibrant'
        ? '/assets/img/generate/example-vibrant.svg'
        : '/assets/img/generate/example-minimal-big.svg';
    },
    generationCompleted() {
      if (this.slides.length) {
        return this.slides.every((i) => i.completed);
      }
      return true;
    },
    coverSlideGenerated() {
      if (!this.slides.length) {
        return true;
      }
      return this.slides.find((i) => i.slide_index === 0)?.completed;
    },
    selectedSlide() {
      return this.slides.find((i) => i.id === this.selectedSlideID);
    },
    selectedSlidePos() {
      return this.slides.findIndex((i) => i.id === this.selectedSlideID);
    },
    currentPrompt() {
      return this.contextPrompts.find(
        (i) => i.type === this.getContextualPrompt,
      );
    },
    displayContextPrompt() {
      return (
        this.isContextPromptSwitchOn &&
        this.currentPrompt &&
        this.showContextPrompt
      );
    },
    promptTitle() {
      if (!this.currentPrompt) {
        return '';
      }
      switch (this.currentPrompt.type) {
        case 'AUDIENCE_NOT_COMPLETED_FINGERPRINT':
          return `${this.captializeAudience} has not completed their fingerprint test.`;
        case 'NO_ACCESS_TO_AUDIENCE':
          return `${this.captializeAudience}'s communication preferences are locked.`;
        case 'AUDIENCE_NOT_AVAILABLE':
          return `${this.captializeAudience} is currently not on Prezent.`;
        case 'NO_TEMPLATE_DETECTED':
          return 'Create your slide in the template of your choice!';
        case 'NO_MULTIPLE_SLIDES':
          return 'Want to create a presentation with multiple slides?';
        default:
          return 'Did you know?';
      }
    },
    promptAudience() {
      if (this.isSlideGenerated) {
        return this.audience.id === this.currentUser.user.id
          ? 'Me'
          : this.audience.fullName;
      }
      return '';
    },
    promptTemplate() {
      if (this.isSlideGenerated && this.template) {
        return (
          this.themes.find((i) => this.template === i.code)?.name ||
          this.currentTheme.name
        );
      }
      return '';
    },
    promptBody() {
      if (!this.currentPrompt) {
        return '';
      }
      switch (this.currentPrompt.type) {
        case 'AUDIENCE_NOT_COMPLETED_FINGERPRINT':
          return 'You can send a reminder or take a fingerprint test on their behalf';
        case 'NO_ACCESS_TO_AUDIENCE':
          return 'You can request access or take a fingerprint test on their behalf ';
        case 'AUDIENCE_NOT_AVAILABLE':
          return 'You can add their fingerprint or share a free trial with them!';
        case 'NO_MULTIPLE_SLIDES':
          return "Don't worry! We are working hard at it and will soon add the capability.";
        default:
          return 'Did you know?';
      }
    },
    clearContextDisabled() {
      if (this.loading || this.loaderState) {
        return true;
      }
      if (
        this.getDeckContext &&
        (this.getDeckContext.texts?.text?.length ||
          this.getDeckContext.links?.length ||
          this.getDeckContext.files?.length)
      ) {
        return false;
      }
      if (
        this.slides.some(
          (slide) =>
            slide.context.texts.text.length ||
            slide.context.links.length ||
            slide.context.files.length,
        )
      ) {
        return false;
      }
      return true;
    },
    processInProgress() {
      return (this.loading || !this.generationCompleted) && !this.changeReq;
    },

    mapUserDownloadPreferenceToTooltip() {
      const userDownloadPreference =
        this.currentUser?.user?.userDownloadPreference?.toLowerCase();
      if (userDownloadPreference === 'local_download') {
        return this.$t('common.downloadPPTX');
      }
      if (userDownloadPreference === 'save_to_gdrive') {
        return this.$t('common.googleDownload');
      }
      return this.$t('common.download');
    },
  },
  methods: {
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
      'setAddedToLibOrFavSnackbar',
      'setNavFromAddToLibOrFavSnackbar',
    ]),
    ...mapActions('users', ['toggleUserAGPromptSuggestions']),
    dragEnd() {
      this.resetSlideLibraryFlags();
    },
    toggleLivePrompt() {
      this.toggleUserAGPromptSuggestions();
      const otherData = {
        [TD_PLACEMENT]: TD_ICON_AT_THE_TOP,
        [TD_COMMON_COLUMN_NAME]: 'On or off',
        [TD_COMMON_COLUMN_VALUE]: this.getAGLiveSuggestionEnabled
          ? 'On'
          : 'Off',
        [TD_INSTRUCTIONS]: this.prompMsg,
      };
      trackGenerateEvents.generatePromptSuggestionsOnOff(
        this.currentUser,
        otherData,
      );
    },
    displayDayZeroPopup() {
      this.$modal.show(
        DayZeroPopup,
        {},
        {
          name: 'DayZeroPopup',
          width: '500px',
          height: '500px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    handleProTip(proTip) {
      if (
        ['none_of_the_above', 'long_prompt', 'unclear_intent'].includes(
          proTip.toLowerCase(),
        )
      ) {
        this.openRightPanel('promptExamples', 'top-panel');
      } else if (proTip.toLowerCase() === 'data_visualization') {
        this.$router.push(
          `/home/slides?query=all&category=All&filter=graphs`,
          () => {},
        );
      } else {
        this.$router.push(`/home/slides`, () => {});
      }
    },
    handleClearContext() {
      this.changeReq = {
        type: 'clear_context',
      };
      this.handleConfirmation(this.changeReq);
      trackGenerateEvents.generateClearContextClick(this.currentUser, {});
    },
    cancelRequestClicked() {
      if (this.uuid && this.slides.length > 0) {
        this.reportErrorRequest(this.uuid, 'Interrupted');
      }
      this.cancelRequest();
    },
    clearContext() {
      this.setDeckContext({
        texts: { text: '' },
        links: [],
        files: [],
        extract_graph_data: false,
      });
      if (this.slides.length) {
        this.slides.forEach((slide) => {
          slide.context = {
            texts: { text: '' },
            files: [],
            links: [],
            instructions: '',
            extractedImages: [],
            linkAccess: false,
            extract_graph_data: false,
          };
        });
      }
    },
    goToFavLibrary() {
      // console.log(this.prezOrSlideId);
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: this.slides.length > 1 ? 'addToFavPrez' : 'addToFavSlides',
        origin: this.slides.length > 1 ? 'fav_prez' : 'fav_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.slides.length > 1) {
        console.log('prez');
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        console.log('slide');
        this.$router.push('/home/slides').catch(() => {});
      }
    },
    goToLibrary() {
      console.log(this.prezOrSlideId);
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: this.slides.length > 1 ? 'addToLibPrez' : 'addToLibSlides',
        origin:
          this.slides.length > 1 ? 'generate_lib_prez' : 'generate_lib_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.slides.length > 1) {
        console.log('prez');
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        console.log('slide');
        this.$router.push('/home/slides').catch(() => {});
      }
    },
    pageChangeAction(navigationNext) {
      if (this.deckValid) {
        this.navigationNext = navigationNext;
        this.$modal.show(
          GenerateActionModal,
          {
            heading: this.$t('premiumFeature.unsavedChanges'),
            primaryBtnText: this.$t('common.save'),
            secondaryBtnText: this.$t('premiumFeature.continueWithoutSaving'),
            modalType: 'unsaved',
            primaryBtnAction: this.saveChanges,
            secondaryBtnAction: this.discardChanges,
            type: this.slides.length > 1 ? 'prezentation' : 'slide',
            operation: this.generateOperation,
            filename: this.modifiedFileName || this.filename,
            originPage: 'generated',
            getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
            logDownload: this.handleDownloadLog,
            closePreviousPopups: this.handleClosePopAndSaveToLib,
          },
          {
            name: 'GenerateActionModal',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      } else {
        navigationNext();
      }
    },
    async saveChanges(name) {
      await this.updateAddedToSlideLibraryStatus(name);
      this.$modal.hide('GenerateActionModal');
      this.discardChanges();
    },
    discardChanges() {
      this.navigationNext();
    },
    // closePopup() {
    //   // this.isAddedToLibraryDownload = false;
    //   // this.resetAddedToLibrarySnackbarState();
    // },
    async undo(deckLevel) {
      if (this.error || deckLevel) {
        this.error = null;
        this.audience = this.lastSuccessfulDeck.audience;
        this.template = this.lastSuccessfulDeck.template;
        this.isAddedAsFavorite = this.lastSuccessfulDeck.isAddedAsFavorite;
        this.isAddedToLibrary = this.lastSuccessfulDeck.isAddedToLibrary;
        this.form = this.lastSuccessfulDeck.form;
        this.addedToLibraryData = this.lastSuccessfulDeck.addedToLibraryData;
        // this.handleDeck(this.lastSuccessfulDeck.uuid);
        this.uuid = this.lastSuccessfulDeck.uuid;
        this.slides = [...this.lastSuccessfulDeck.slides];
        this.selectedSlideID = this.slides[0].id;
      } else {
        const selectedSlideID = this.selectedSlide.id;
        const targetSlide = this.slides.find((i) => i.id === selectedSlideID);
        Vue.set(this.slides, this.selectedSlidePos, {
          lastSuccessfulSlide: {
            ...targetSlide.lastSuccessfulSlide,
            completed: true,
            error: false,
          },
          ...targetSlide.lastSuccessfulSlide,
          completed: true,
          error: false,
        });
        this.selectedSlideID = targetSlide.lastSuccessfulSlide.id;
      }
    },
    tryAddSlideAgain() {
      this.deleteSlide(this.selectedSlidePos, true);
      this.openRightPanel('AddSlide');
    },
    async startBulkStatus() {
      try {
        const callbackIds = this.slideCallbackIds.map(
          (slide) => slide.job_callbak_id,
        );
        const response = await checkBulkStatus(callbackIds);
        for (const slide of this.slideCallbackIds) {
          const result = response[slide.job_callbak_id];
          const status = result?.workflow_status;
          if (status === 'success') {
            // eslint-disable-next-line camelcase
            const { outputs, execution_end, execution_start } = result;
            this.getSlideDetails(slide, {
              ...outputs,
              execution_end,
              execution_start,
            })
              .then((newSlide) => {
                const slideIndex = this.slides.findIndex(
                  (s) => s.job_callbak_id === slide.job_callbak_id,
                );
                Vue.set(this.slides, slideIndex, {
                  ...this.slides[slideIndex],
                  ...newSlide,
                  lastSuccessfulSlide: {
                    ...this.slides[slideIndex],
                    ...newSlide,
                    error: false,
                    completed: true,
                    processing: false,
                  },
                  error: false,
                  completed: true,
                  processing: false,
                });
              })
              .catch(() => {
                const slideIndex = this.slides.findIndex(
                  (s) => s.job_callbak_id === slide.job_callbak_id,
                );
                Vue.set(this.slides, slideIndex, {
                  ...this.slides[slideIndex],
                  error: true,
                  completed: true,
                  processing: false,
                });
              });
          }
          if (status === 'failed') {
            const slideIndex = this.slides.findIndex(
              (s) => s.job_callbak_id === slide.job_callbak_id,
            );
            console.log('Error in slide', slideIndex + 1);
            Vue.set(this.slides, slideIndex, {
              ...this.slides[slideIndex],
              error: true,
              completed: true,
              processing: false,
            });
          }
          // Remove from callback list
          if (['success', 'failed'].includes(status)) {
            this.slideCallbackIds = this.slideCallbackIds.filter(
              (sld) => sld.job_callbak_id !== slide.job_callbak_id,
            );
          }
        }
        if (this.slideCallbackIds.length) {
          setTimeout(() => {
            this.startBulkStatus();
          }, 2000);
        } else {
          this.statusApiInProgress = false;
        }
      } catch (err) {
        console.log(err);
        this.cancelRequest();
      }
    },
    onImageRender() {
      this.imageRendered = true;
    },
    onImageReplaced(change) {
      this.showConfirmation = true;
      this.changeReq = change;
      this.dialogTitle = 'Exit Replace Visuals';
      this.dialogText = `Any changes to the slide will not be saved. Would you like to leave anyway?`;
      this.dialogPrimary = 'Yes';
      this.replaceVisualCancel = 'No';
    },
    handleClearPrompt() {
      this.prompMsg = '';
      trackGenerateEvents.generatePromptClearButtonClick(this.currentUser);
    },
    ...mapActions('generateSlides', [
      'setSmartToolsOpen',
      'setRightPanelComponent',
      'setIsSlideGenerated',
      'setRelatedSlides',
      'setSiblingNodes',
      'toggleMagicFill',
      'setPromptLoadIndex',
      'setPromptText',
      'setGenerateResponse',
      'setAudience',
      'setTemplate',
      'setNodeCount',
      'setFilePath',
      'setFileContent',
      'setThumbnail',
      'setGenerateSlide',
      'showLoader',
      'resetState',
      'setAddToSlideLibraryResponse',
      'setGenerateLoading',
      'setImageDetection',
      'setReplaceVisuals',
      'setDeckContext',
    ]),
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    closeFeedback() {
      this.feedbackPopup = false;
    },
    submitRatings() {
      this.feedbackSuccess = true;
      const payload = {
        uuid: this.uuid,
        type: 'feedback',
        value: this.form.feedback,
      };
      generateSlidePostCompleteLog(payload).then((resp) => {
        console.log(resp);
      });
      const otherData = {
        [TD_COMMON_COLUMN_NAME]: TD_RATING,
        [TD_COMMON_COLUMN_VALUE]: this.form.isLiked ? 'Like' : 'Dislike',
        [TD_INSTRUCTIONS]: this.form.feedback,
      };
      trackGenerateEvents.generateLikeDislikeClickSubmit(
        this.currentUser,
        otherData,
      );
    },
    handleRatingClick(type) {
      this.form.feeedback = '';
      switch (type) {
        case 'like':
          if (this.form.isLiked) {
            this.form.isLiked = null;
          } else {
            this.form.isLiked = true;
          }
          break;
        case 'dislike':
          if (this.form.isLiked || this.form.isLiked === null) {
            this.form.isLiked = false;
          } else {
            this.form.isLiked = null;
          }
          break;
        default:
          break;
      }
      const otherData = {
        [TD_COMMON_COLUMN_NAME]: TD_RATING,
        [TD_COMMON_COLUMN_VALUE]: type[0].toUpperCase() + type.slice(1),
      };
      trackGenerateEvents.generateLikeDislikeClick(this.currentUser, otherData);
      const payload = {
        uuid: this.uuid,
        type: 'liked',
        value: this.form.isLiked,
      };
      generateSlidePostCompleteLog(payload).then((resp) => {
        console.log(resp);
      });
      if (this.form.isLiked !== null) {
        this.$nextTick(() => {
          this.feedbackSuccess = false;
          setTimeout(() => {
            this.feedbackPopup = true;
          }, 300);
        });
      }
    },
    ...mapActions('users', ['setCurrentUser']),
    onContextPromptClose(opened) {
      if (!opened) {
        this.primaryBtnText = '';
        this.primaryDisabled = false;
        this.showContextPrompt = false;
      }
    },
    contextSecondaryBtnClick(type) {
      switch (type) {
        case 'AUDIENCE_NOT_COMPLETED_FINGERPRINT':
        case 'NO_ACCESS_TO_AUDIENCE':
          this.$modal.show(
            AddCollegueDetail,
            {
              origin: 'generate',
            },

            AddNewAudienceModalProps,
          );
          break;
        case 'AUDIENCE_NOT_AVAILABLE':
          this.$modal.show(
            ShareFreeTrailNew,
            {
              close: this.closeShareTrialModal,
              origin: 'generate',
            },
            {
              name: 'ShareFreeTrailNew',
              classes: 'v-application',
              width: 800,
              height: 'auto',
              minHeight: 300,
              styles: { borderRadius: '20px', padding: '20px' },
            },
          );
          break;
        default:
          break;
      }
    },
    contextPrimaryBtnClick(type) {
      switch (type) {
        case 'NO_AUDIENCE_DETECTED':
          this.openRightPanel('audience');
          break;
        case 'AUDIENCE_NOT_COMPLETED_FINGERPRINT': {
          sendFPReminder(
            this.currentUser,
            this.audience.audience_contextual_prompt,
            this.audience.audience_contextual_prompt.type === 'user',
          ).then(() => {
            this.primaryBtnText = 'Reminder sent!';
            this.primaryDisabled = true;
          });
          break;
        }
        case 'NO_ACCESS_TO_AUDIENCE': {
          audienceApi.methods
            .requestFingerprintAccess({
              type: this.audience.audience_contextual_prompt.type,
              id:
                this.audience.audience_contextual_prompt.type === 'user'
                  ? this.audience.audience_contextual_prompt.email
                  : this.audience.audience_contextual_prompt.uuid,
            })
            .then(() => {
              this.primaryBtnText = 'Request sent!';
              this.primaryDisabled = true;
            });
          break;
        }
        case 'NODE_NOT_DETECTED':
          this.openRightPanel('nodeCount');
          break;
        case 'NO_TEMPLATE_DETECTED':
          this.openRightPanel('template-menu');
          break;
        case 'AUDIENCE_NOT_AVAILABLE':
          this.$modal.show(
            AddCollegueDetail,
            {
              origin: 'generate',
            },

            AddNewAudienceModalProps,
          );
          break;
        default:
          break;
      }
    },
    closeShareTrialModal() {
      this.$modal.hide('ShareFreeTrailNew');
    },
    cancelRequest() {
      this.generateLoader.nextState(0);
      this.generateSmartToolsLoader.nextState(0);
      this.checkingUsage = false;
      this.loading = false;
      this.replacingImage = false;
      this.generatingNewSlide = false;
      this.closeSmartTool();
      this.slideCallbackIds = [];
      this.statusApiInProgress = false;
      if (this.uuid && this.slides.length === 0) {
        this.reportErrorRequest(this.uuid, 'Interrupted');
        cancelGSRequest(this.uuid).then((resp) => {
          console.log(resp);
          // this.uuid = null;
        });
        if (this.lastSuccessfulDeck) {
          this.undo(true);
        }
      }
      if (this.slides.length) {
        this.slides.forEach((slide, index) => {
          if (!slide.completed) {
            if (slide.job_callbak_id) {
              cancelGSRequest(slide.job_callbak_id).then((resp) => {
                console.log(resp);
              });
            }
            if (slide.lastSuccessfulSlide) {
              const idx = this.slides.findIndex((i) => i.id === slide.id);
              Vue.set(this.slides, idx, {
                lastSuccessfulSlide: {
                  ...slide.lastSuccessfulSlide,
                  completed: true,
                  error: false,
                },
                ...slide.lastSuccessfulSlide,
                completed: true,
                error: false,
              });
            } else if (slide.addNewSlide) {
              this.deleteSlide(index, true);
            } else {
              slide.processing = false;
              slide.completed = true;
              slide.error = true;
            }
          }
        });
        // this.disableActions = false;
      }
      this.pollingInterval.forEach((i) => {
        clearInterval(i);
      });
      trackGenerateEvents.generateCancelButtonClick(this.currentUser);
    },
    saveDeckInfo() {
      if (this.slides.length && this.uuid) {
        this.lastSuccessfulDeck = {
          uuid: this.uuid,
          slides: this.slides.map((i) =>
            i.completed || !i.lastSuccessfulSlide
              ? { ...i }
              : { ...i.lastSuccessfulSlide },
          ),
          audience: this.audience,
          template: this.template,
          isAddedAsFavorite: this.isAddedAsFavorite,
          isAddedToLibrary: this.isAddedToLibrary,
          addedToLibraryData: this.addedToLibraryData,
          form: this.form,
        };
      }
    },
    duplicateSlide(slide, index) {
      this.resetSlideLibraryFlags();
      this.slides.splice(index, 0, {
        ...slide,
        id: uuidv4(),
        duplicated: true,
      });
      this.postSlideLog('duplicate_slide', slide);
      trackGenerateEvents.generateSlideDuplicate(this.currentUser);
    },
    deleteSlide(index, cancelCall) {
      this.resetSlideLibraryFlags();
      const ind = this.selectedSlidePos;
      if (!cancelCall) this.postSlideLog('delete_slide', this.slides[index]);
      this.slides.splice(index, 1);
      if (ind === index) {
        this.selectedSlideID = this.slides[0].id;
      }
      if (
        this.slides.length === 1 &&
        ['AddSlide', 'AboutPrezentation'].includes(this.rightPanelComponent)
      ) {
        this.closeSmartTool();
      }
      trackGenerateEvents.generateSlideDelete(this.currentUser);
    },
    handlePromptTextFieldFocus() {
      this.isPromptBoxFocused = true;
      this.getPromptDropdwonWidthHeight();
    },
    clickOutsidePromptBox() {
      this.isPromptBoxFocused = false;
    },

    handleInput(e) {
      this.prompMsg = e || '';
      if (!e) {
        this.$nextTick(() => {
          this.getPromptDropdwonWidthHeight();
        });
      }
    },

    handlePromptTextFieldBlur($event) {
      if ($event.relatedTarget?.id === 'pendo-guide-container') {
        // Not letting dropdown hidden when pendo guide step is focused.
        return;
      }
      if (this.prompMsg.length === 0) {
        setTimeout(() => {
          this.isPromptBoxFocused = false;
          this.getPromptDropdwonWidthHeight();
        }, 500);
      }
    },
    // isGenerateFullAccessBannerCheck() {
    //   const synFeature = this.currentUser?.features?.find(
    //     (ft) => ft.feature_name === 'slide-generate',
    //   );
    //   return (
    //     synFeature &&
    //     synFeature.enabled &&
    //     synFeature.access_level &&
    //     synFeature.access_level === 'full_access'
    //   );
    // },
    // onDismiss() {
    //   const userData = {
    //     performIncrements: {
    //       generateBanner: 1,
    //     },
    //   };
    //   usersApi.methods
    //     .updateUserProfile(userData)
    //     .then((updatedUser) => {
    //       const userInfo = this.currentUser;
    //       userInfo.user = updatedUser;
    //       this.setCurrentUser(userInfo);
    //     })
    //     .catch((err) => console.log(err));
    // },
    toggleContext() {
      this.isContextPromptSwitchOn = !this.isContextPromptSwitchOn;
      trackGenerateEvents.generateContextualPromtClick(this.currentUser, {
        [TD_NODECOUNT]: this.selectedSlide?.slideDetails?.prefs?.node || '',
      });
    },
    openSmartTools(val = '') {
      this.setSmartToolsOpen(true);
      if (val === 'onClick') {
        trackGenerateEvents.generateSmartToolsClick(this.currentUser);
      }
    },
    closeSmartTool() {
      this.setSmartToolsOpen(false);
    },
    openRightPanel(type, origin = null, clickName = null) {
      setTimeout(() => {
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }, 50);
      if (origin && origin === 'top-panel') {
        this.isFromTopPanel = true;
      } else this.isFromTopPanel = false;

      if (origin && origin === 'smart-tools') {
        this.isFromSmartTools = true;
      } else this.isFromSmartTools = false;

      if (type === this.rightPanelComponent) {
        this.setRightPanelComponent(null);
      } else {
        this.setSmartToolsOpen(true);
        this.setRightPanelComponent(type);
      }
      if (clickName === 'editSlideCount') {
        trackGenerateEvents.generateEditSlideCountClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      }
      if (type === 'changeLayout') {
        trackGenerateEvents.generateSmartLayoutClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      } else if (type === 'MagicFill') {
        let placeMent = TD_SMART_TOOLS;
        if (origin === 'cta-btn') {
          placeMent = TD_CTA_BUTTON;
        } else if (origin === 'top-panel') {
          placeMent = TD_ABOVE_THE_GENERATED_SLIDE;
        }
        trackGenerateEvents.generateMagicFillClick(this.currentUser, {
          [TD_PLACEMENT]: placeMent,
        });
      } else if (type === 'audience') {
        trackGenerateEvents.generateAudienceClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      } else if (type === 'template-menu') {
        trackGenerateEvents.generateTemplateClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      } else if (type === 'nodeCount') {
        trackGenerateEvents.generateNodeCountClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      } else if (type === 'replaceVisual') {
        trackGenerateEvents.generateVisualClick(this.currentUser, {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
        });
      } else if (type === 'AboutPrezentation') {
        trackGenerateEvents.generateAboutYourPrezentationClick(
          this.currentUser,
          {
            [TD_PLACEMENT]: TD_SMART_TOOLS,
          },
        );
      } else if (type === 'promptExamples') {
        const otherData = {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_ICON_ON_TOP,
        };
        trackGenerateEvents.generatePromptExamplesClick(
          this.currentUser,
          otherData,
        );
      }
    },
    magicFillChanged(val) {
      if (val) {
        this.showLoader();
      }
      this.setMagicFill(val);
    },
    onDialogConfirm() {
      this.showConfirmation = false;
      this.handleChange();
    },
    onDialogCancel() {
      this.changeReq = null;
      this.showConfirmation = false;
    },
    resetSlideLibraryFlags() {
      this.isAddedToLibrary = false;
      this.isAddedAsFavorite = false;
      this.addedToLibraryData = null;
      this.modifiedFileName = '';
    },
    replaceImageError({ id }) {
      const ind = this.slides.findIndex((i) => i.id === id);
      this.slides[ind].completed = true;
      this.slides[ind].error = true;
      this.replacingImage = false;
    },
    async startReplaceVisuals(val) {
      this.changeReq = {
        type: 'replaceVisuals',
      };
      this.error = null;
      this.replacingImage = true;
      this.showLoader('replaceVisual');
      this.generatingNewSlide = true;
      const selectedSlideID = this.selectedSlide.id;
      const ind = this.slides.findIndex((i) => i.id === selectedSlideID);
      this.slides[ind].completed = false;
      const resp = await triggerReplaceImage(
        val.imageToReplace,
        val.currentSelectedSlideImageData,
        this.slides[ind],
        this.currentUser,
      );
      const idx = this.slides.findIndex((i) => i.id === selectedSlideID);
      if (!this.error && !resp.error) {
        if (!this.slides[idx].completed) {
          Vue.set(this.slides, idx, {
            ...this.slides[idx],
            lastSuccessfulSlide: {
              ...this.slides[idx],
              slide_path: resp.path,
              slide_thumbnail: resp.thumbnail,
              landscape: resp.landscape,
              completed: true,
              error: false,
            },
            slide_path: resp.path,
            slide_thumbnail: resp.thumbnail,
            landscape: resp.landscape,
            completed: true,
            error: false,
          });
          this.generatingNewSlide = false;
          this.replacingImage = false;
          this.$refs.replaceVisual?.selectImage(
            val.imageToReplace,
            val.currentSelectedSlideImageData,
            selectedSlideID,
          );
          const replaceSource = val.imageToReplace?.source;
          let matomoReplaceSource = '';
          if (replaceSource === 'adobe' || replaceSource === 'freepik')
            matomoReplaceSource = 'Library';
          if (replaceSource === 'brand-images')
            matomoReplaceSource = this.currentUser?.company?.displayName;
          if (replaceSource === 'upload') matomoReplaceSource = 'Upload';
          trackGenerateEvents.generateVisualChanged(this.currentUser, {
            [TD_REPLACE_IMAGE_SOURCE]: matomoReplaceSource,
            [TD_PLACEMENT]: !this.isFromTopPanel
              ? TD_SMART_TOOLS
              : TD_ABOVE_THE_GENERATED_SLIDE,
          });
          this.resetSlideLibraryFlags();
          this.postSlideLog('replaceVisuals', this.slides[idx]);
        }
      } else if (resp.error && !this.slides[idx].completed) {
        this.slides[idx].completed = true;
        this.slides[idx].error = true;
        this.replacingImage = false;
        this.postSlideLog('replaceVisuals', this.slides[idx]);
      }
    },
    handleConfirmation(change) {
      this.resetSlideLibraryFlags();
      this.changeReq = change;
      switch (change.type) {
        case 'clear_context':
          this.dialogTitle =
            'Are you sure you would like to clear the context provided?';
          this.dialogText = `The text, cloud or web links, and local files you provided as context for this deck or for individual slides will be cleared, and all the content will be regenerated. `;
          this.dialogPrimary = 'Clear context';
          this.showConfirmation = true;
          break;
        case 'context_change':
        case 'duration_change':
        case 'add_new_slide':
          this.handleChange();
          break;
        case 'audience_change':
          this.dialogTitle =
            'Are you sure you would like to change your audience? ';
          this.dialogText = `Switching the audience may result in the loss of any text on the current ${
            this.slides.length > 1 ? 'prezentation' : 'slide'
          }.`;
          this.dialogPrimary = 'Change audience';
          this.showConfirmation = true;
          break;
        case 'template_change':
          this.dialogTitle =
            'Are you sure you would like to change your template? ';
          this.dialogText = `Switching the template may result in the loss of any text on the current ${
            this.slides.length > 1 ? 'prezentation' : 'slide'
          }.`;
          this.dialogPrimary = 'Change template';
          this.showConfirmation = true;
          break;
        case 'node_change':
          this.dialogTitle =
            'Are you sure you would like to change the node count? ';
          this.dialogText =
            'Changing the node count may result in the loss of any text on the current slide.';
          this.dialogPrimary = 'Change node count';
          this.showConfirmation = true;
          break;
        case 'slide_change':
          this.dialogTitle =
            'Are you sure you would like to change the layout? ';
          this.dialogText =
            'Changing the layout may result in the loss of any text on the current slide.';
          this.dialogPrimary = 'Change layout';
          this.showConfirmation = true;
          break;
        default:
          break;
      }
      if (change?.type === 'context_change' && change?.subType === 'deck') {
        trackGenerateEvents.generateAddContextRegenerateDeck(this.currentUser, {
          [TD_PLACEMENT]: !this.isFromTopPanel
            ? TD_SMART_TOOLS
            : TD_ABOVE_THE_GENERATED_SLIDE,
          [TD_COMMON_COLUMN_NAME]: 'Context added',
          [TD_NUMBEROFSLIDES]: this.slides.length,
          [TD_COMMON_COLUMN_VALUE]: this.getAGMatomoContext(
            this.getDeckContext,
          ),
        });
      }
      if (change?.type === 'context_change' && change?.subType === 'slide') {
        console.log(change, 'change slide');
        trackGenerateEvents.generateAddContextRegenerateSlide(
          this.currentUser,
          {
            [TD_PLACEMENT]: !this.isFromTopPanel
              ? TD_SMART_TOOLS
              : TD_ABOVE_THE_GENERATED_SLIDE,
            [TD_COMMON_COLUMN_NAME]: 'Context added',
            [TD_COMMON_COLUMN_VALUE]: this.getAGMatomoContext(change?.context),
            [TD_NUMBEROFSLIDES]: 1,
          },
        );
      }
    },
    getAGMatomoContext(context) {
      const text = context?.texts?.text.length > 0 ? 'Yes' : 'No';
      const weblink = context?.links?.length > 0 ? 'Yes' : 'No';
      const localfiles = context?.files?.length > 0 ? 'Yes' : 'No';
      const textLeng =
        context?.texts?.text.length > 0 ? context?.texts?.text.length : NA;
      const webLinkCount =
        context?.links?.length > 0 ? context?.links?.length : NA;
      const localFilesCount =
        context?.files?.length > 0 ? context?.files?.length : NA;
      return `Text-${text}-${textLeng}_weblink-${weblink}-${webLinkCount}_localfiles-${localfiles}-${localFilesCount}`;
    },
    postLog(changeReq) {
      if (
        this.generationCompleted &&
        ((changeReq &&
          [
            'slide_change',
            'node_change',
            'context_change',
            'replaceVisuals',
          ].includes(changeReq.type)) ||
          !this.lastSuccessfulDeck ||
          this.lastSuccessfulDeck.uuid !== this.uuid)
      ) {
        const req = this.getGenerateLog(changeReq);
        generateSlideCompleteLog(req).then((res) => {
          console.log(res, 'complete log');
        });
      }
    },
    postSlideLog(operation, slide) {
      if (this.slides.length === 1) {
        this.postLog({ type: operation });
      } else {
        let req;
        if (slide.error) {
          req = {
            uuid: this.uuid,
            slide: {
              uuid: slide.job_callbak_id,
              operation,
              status: 'failed',
            },
          };
        } else {
          req = {
            uuid: this.uuid,
            slide: {
              uuid: slide.job_callbak_id,
              uniqueID: slide.slideDetails.unique_id,
              category: slide.slideDetails.categories[0],
              construct: slide.slideDetails.constructs[0],
              assetID: slide.slideDetails.unique_id,
              s3: slide.slide_path.startsWith('/newImageReplaceOutput/output/')
                ? calyrexConfig.calyrex_bucket_name
                : process.env.VUE_APP_MVP_ASSETS_BUCKET,
              fileName: slide.slide_path,
              thumbnail: slide.slide_thumbnail,
              node: slide.slide_node_count,
              image: slide.image_keyword,
              extractedImages: slide.extractedImages,
              secondaryPrompt:
                operation === 'context_change'
                  ? slide.context.instructions
                  : '',
              execution_end: moment.utc(
                slide.execution_end,
                'DD/MM/YY HH:mm:ss:SSSSSS',
              ),
              execution_start: moment.utc(
                slide.execution_start,
                'DD/MM/YY HH:mm:ss:SSSSSS',
              ),
              slideTitle: slide.slide_title,
              slideType: slide.slide_type,
              operation,
              ...((operation === 'context_change' ||
                operation === 'add_new_slide') && {
                files: slide.context.files,
                texts: [(({ text, ...o }) => o)(slide.context.texts)],
                links: slide.context.links,
              }),
            },
          };
        }
        generateSlideUpdateLog(req).then((res) => {
          console.log(res, 'slide complete log');
        });
      }
    },
    async addNewSlide(change) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.generate?.isLimitCrossed ||
        this.limitsInfoObj?.generate?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'generate' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
        this.resetSlideLibraryFlags();
        this.loading = true;
        if (change.context.texts.text.length) {
          const file = await this.createTextFile(change.context.texts.text);
          change.context.texts = {
            ...file,
            text: change.context.texts.text,
          };
        }
        const body = await this.getGenerateDeckRequest(change);
        body.workflow_context.operation = 'add_slide';
        body.workflow_context.prompt = change.prompt;
        generateSlideRequest(body).then(async () => {
          const id = uuidv4();
          if (change.slidePos > this.slides.length) {
            this.slides.push({
              slide_callback_id: body.callback_id,
              job_callbak_id: null,
              id,
              completed: false,
              siblings: [],
              relatedSlides: [],
              addNewSlide: true,
              context: { ...change.context, instructions: '' },
            });
          } else {
            this.slides.splice(change.slidePos - 1, 0, {
              slide_callback_id: body.callback_id,
              job_callbak_id: null,
              id,
              completed: false,
              siblings: [],
              relatedSlides: [],
              addNewSlide: true,
              context: { ...change.context, instructions: '' },
            });
          }
          this.selectedSlideID = id;
          checkGSStatus(body.callback_id, (interval) => {
            this.pollingInterval.push(interval);
          })
            .then(async (resp) => {
              const jobID =
                resp.outputs.SlideTrigger.events[0].slides[0].job_callbak_id;
              this.slides[this.selectedSlidePos].job_callbak_id = jobID;
              const extractedImages =
                resp.outputs.SlideAggregator.output.extracted_images;
              extractedImages.forEach(async (image, index) => {
                if (!this.extractedImages.find((i) => i.s3_path === image)) {
                  const extImg = await getFileURL(
                    this.currentUser.user.cognitoID,
                    `/${image}`,
                    this.currentUser.userIp,
                  );
                  this.extractedImages.unshift({
                    id: index,
                    url: extImg,
                    s3_path: image,
                  });
                }
              });
              try {
                const slide = await this.getSlideInfo(
                  this.slides[change.slidePos - 1],
                );
                const links =
                  resp.outputs.SlideAggregator.output.processed_files?.links ||
                  slide.context.links;
                console.log(slide);
                slide.context = { ...slide.context, links };
                const ind = this.slides.findIndex((j) => j.id === id);
                if (ind >= 0) {
                  this.slides[ind] = { ...slide };
                  Vue.set(this.slides, ind, {
                    ...slide,
                    completed: true,
                    error: false,
                    extractedImages,
                    addNewSlide: false,
                    lastSuccessfulSlide: {
                      ...slide,
                      addNewSlide: false,
                      completed: true,
                      error: false,
                    },
                  });
                }
                this.postSlideLog('add_new_slide', slide);
                this.loading = false;
              } catch (err) {
                console.log(err);
                const ind = this.slides.findIndex((j) => j.id === id);
                console.log('herre in the error', ind, err);
                Vue.set(this.slides, ind, {
                  ...this.slides[ind],
                  error: true,
                  completed: true,
                });
                // this.postLog();
                this.loading = false;
              }
            })
            .catch((err) => {
              console.log(err);
              const ind = this.slides.findIndex((j) => j.id === id);
              Vue.set(this.slides, ind, {
                ...this.slides[ind],
                completed: true,
                error: err.log === 'sb-toxicity' ? 'TOXICERR' : true,
              });
              // this.postLog();
              this.loading = false;
            });
        });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      }
    },
    async handleChange() {
      this.saveDeckInfo();
      switch (this.changeReq.type) {
        case 'clear_context':
          this.clearContext();
          break;
        case 'duration_change':
          this.generateDeck();
          break;
        case 'add_new_slide':
          this.addNewSlide(this.changeReq);
          break;
        case 'audience_change':
          this.audience = {
            ...this.changeReq.val,
          };
          // this.showLoader('audience');
          this.generateDeck();
          trackGenerateEvents.generateAudienceToolChanged(this.currentUser, {
            [TD_PLACEMENT]: !this.isFromTopPanel
              ? TD_SMART_TOOLS
              : TD_ABOVE_THE_GENERATED_SLIDE,
            [TD_NUMBEROFSLIDES]: this.slides.length,
          });
          break;
        case 'template_change':
          await getFeatureUsage()
            .then((response) => {
              if (response?.data) {
                this.setLimitData(response.data);
              }
            })
            .catch((error) => console.log(error));
          if (
            !this.limitsInfoObj?.generate?.isLimitCrossed ||
            this.limitsInfoObj?.generate?.isFullAccess
          ) {
            this.template = this.changeReq.val;
            // this.showLoader('template');
            this.generateDeck();
            trackGenerateEvents.generateTemplateChanged(this.currentUser, {
              [TD_PLACEMENT]: !this.isFromTopPanel
                ? TD_SMART_TOOLS
                : TD_ABOVE_THE_GENERATED_SLIDE,
              [TD_NUMBEROFSLIDES]: this.slides.length,
            });
          } else {
            this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
          }
          break;
        case 'node_change':
          // this.setGenerateSlide(this.changeReq.slide);
          // this.setNodeCount({
          //   nodeCount: parseInt(this.changeReq.slide.prefs.node, 10),
          // });
          // this.showLoader('nodeCount');
          this.generateSlide();
          trackGenerateEvents.generateNodeCountChanged(this.currentUser, {
            [TD_NODECOUNT]: this.selectedSlide?.slideDetails?.prefs?.node || '',
            [TD_NUMBEROFSLIDES]: this.slides.length,
            [TD_PLACEMENT]: !this.isFromTopPanel
              ? TD_SMART_TOOLS
              : TD_ABOVE_THE_GENERATED_SLIDE,
          });
          break;
        case 'slide_change':
          // this.setGenerateSlide(this.changeReq.slide);
          // this.setNodeCount({
          //   nodeCount: parseInt(this.changeReq.slide.prefs.node, 10),
          // });
          // this.showLoader('smartLayout');
          this.generateSlide();
          trackGenerateEvents.generateSmartLayoutChanged(this.currentUser, {
            [TD_PLACEMENT]: !this.isFromTopPanel
              ? TD_SMART_TOOLS
              : TD_ABOVE_THE_GENERATED_SLIDE,
            [TD_NUMBEROFSLIDES]: this.slides.length,
          });
          break;
        case 'context_change':
          if (this.changeReq.subType === 'deck') {
            this.generate(false, true);
          } else {
            this.showLoader('magicFill');
            this.generateSlide();
            // trackGenerateEvents.generateMagicFillRegenerate(this.currentUser, {
            //   [TD_INSTRUCTIONS]: this.changeReq?.prompt,
            //   [TD_PLACEMENT]: !this.isFromTopPanel
            //     ? TD_SMART_TOOLS
            //     : TD_ABOVE_THE_GENERATED_SLIDE,
            // });
          }
          break;
        default:
          break;
      }
    },
    async handleDownload() {
      this.$modal.show(
        GenerateActionModal,
        {
          heading: `Download ${
            this.slides.length > 1 ? 'Prezentation' : 'Slide'
          }`,
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          type: this.slides.length > 1 ? 'prezentation' : 'slide',
          isAddedToLibrary: this.isAddedToLibrary,
          operation: this.generateOperation,
          filename: this.modifiedFileName || this.filename,
          downloadItem: {
            ...this.selectedSlide.slideDetails,
            name: this.modifiedFileName || this.filename,
            prefs: {
              ...this.selectedSlide.slideDetails.prefs,
              source: 'generated',
            },
            filename: this.selectedSlide.slide_path,
            audienceID: this.audience?.id,
            downloadLoaderId: this.slides[0].id,
          },
          originPage: 'generated',
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          logDownload: this.handleDownloadLog,
          closePreviousPopups: this.handleClosePopAndSaveToLib,
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
      // } else {
      //   this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      // }
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.generateOperation.loading = true;
      const s = {
        slides: [],
      };
      this.slides.forEach((slide) => {
        if (slide.completed && !slide.error) {
          s.slides.push({
            slideId:
              slide.slide_path.charAt(0) === '/'
                ? slide.slide_path.substring(1)
                : slide.slide_path,
            isReplaced:
              slide.slide_path.startsWith('/newImageReplaceOutput/output/') ||
              slide.slide_path.startsWith('/output/pptx/'),
            isUploaded: false,
            isGenerated: true,
          });
        }
      });
      if (s.slides.length) {
        this.setDownloadLoaders({
          loaderId: this.slides[0].id,
          downloading: true,
        });
        this.setShowDownloadSnackbar(true);
        this.setDownloadItems({
          status: 'assembling',
          itemIndex: uuidv4(),
          originType: 'generateDownload',
          logDownloadCallback: async () => {
            if (addToLibraryClick === true && !this.isAddedToLibrary) {
              await this.updateAddedToSlideLibraryStatus(name);
              // this.isAddedToLibraryDownload = true;
              // this.setIsAddedToLibraryDownload(true);
              // this.setPremiumFtLibConfPopupData({
              //   message:
              //     this.slides.length > 1
              //       ? this.$t('premiumFeature.addedToPrezLibrary')
              //       : this.$t('premiumFeature.addedToSlideLibrary'),
              //   btnText: this.$t('premiumFeature.openInLibrary'),
              //   goToLibrary: this.goToLibrary,
              //   cancel: this.closePopup,
              // });
              // setTimeout(() => {
              //   // this.isAddedToLibraryDownload = false;
              //   this.resetAddedToLibrarySnackbarState();
              // }, 10000);
            }

            const body = {
              uuid: this.uuid,
              type: 'download',
              value: true,
            };
            generateSlidePostCompleteLog(body).then((resp) => {
              console.log(resp);
            });

            trackGenerateEvents.generateDownloadClick(this.currentUser, {
              [TD_ISDOWNLOAD]: 1,
              [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
              [TD_NODECOUNT]:
                this.selectedSlide?.slideDetails?.prefs?.node || '',
              [TD_SLIDE_NAME]: this.filename || '',
              [TD_NUMBEROFSLIDES]: this.selectedSlide?.b_total_slides,
            });
          },
          mergeSlidesCallback: () => {
            const payload = {
              uuid: this.uuid,
              noofslides: s.slides.length,
            };
            generateDownload(payload).then((resp) => {
              console.log(resp);
            });
          },
          downloadStatusCallback: () => {
            this.generateOperation.loading = false;
            this.setDownloadLoaders({
              loaderId: this.slides[0].id,
              downloading: false,
            });
            this.$modal.hide('GenerateActionModal');
          },
          metaData: {
            payload: s,
            fileName: name,
            limitsKey: this.slides.length > 1 ? 'prezentation' : 'slide',
            origin: this.slides.length > 1 ? 'prezentation' : 'slide',
            noofslides: this.slides.length,
          },
        });

        // callMergeSlidesApi(s)
        //   .then((res) => {
        //     const mergedslide = res.data;
        //     if (mergedslide.includes('.pptx')) {
        //       const url = `${miscConfig.aws_prezentation_distribution}/${mergedslide}`;
        //       downloadFileWithCustomName(url, name);
        //       this.$modal.hide('GenerateActionModal');
        //       const payload = {
        //         uuid: this.uuid,
        //         noofslides: s.slides.length,
        //       };
        //       generateDownload(payload).then((resp) => {
        //         console.log(resp);
        //       });
        //       const body = {
        //         uuid: this.uuid,
        //         type: 'download',
        //         value: true,
        //       };
        //       generateSlidePostCompleteLog(body).then((resp) => {
        //         console.log(resp);
        //       });
        //       this.generateOperation.loading = false;
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     this.generateOperation.loading = false;
        //   });
      }
      // trackGenerateEvents.generateDownloadClick(this.currentUser, {
      //   [TD_ISDOWNLOAD]: 1,
      //   [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
      //   [TD_NODECOUNT]: this.selectedSlide?.slideDetails?.prefs?.node || '',
      //   [TD_SLIDE_NAME]: this.filename || '',
      //   [TD_NUMBEROFSLIDES]: this.selectedSlide?.b_total_slides,
      // });
    },
    async handleDownloadLog() {
      const DownloadLogPayload = {
        uuid: this.uuid,
        noofslides: this.slides.length,
      };
      await generateDownload(DownloadLogPayload);
      const payload = {
        uuid: this.uuid,
        type: 'download',
        value: true,
      };
      generateSlidePostCompleteLog(payload);
    },
    getSiblings(payload, sid) {
      const getSlides = (resolve, reject) => {
        const related = [];
        related.push({
          unique_id: payload,
          keytype: 'K_SLIDEDATA',
          sid,
        });
        const params = {
          username: this.currentUser.user.cognitoID,
          related,
        };
        const serialized = JSON.stringify(params);

        GraphQLService.requestWithToken(
          graphqlOperation(getRelatedSlides, {
            parameters: serialized,
          }),
        )
          .then(async (res) => {
            const result = JSON.parse(res.data.getRelatedSlides);
            if (result.statusCode === 200) {
              const assets = [];
              const slides = result.body.payloads;
              if (!slides || slides.length < 1) {
                reject(assets);
              } else {
                for (const slide of slides) {
                  const obj = {};
                  obj.asset = slide;
                  obj.asset.name = obj.asset.title;
                  obj.asset.file = obj.asset.filename;
                  obj.asset.pptFile = obj.asset.file;
                  obj.landscape = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.landscape,
                    this.currentUser.userIp,
                  );
                  obj.poster = await getFileURL(
                    this.currentUser.user.cognitoID,
                    obj.asset.poster,
                    this.currentUser.userIp,
                  );
                  assets.push(obj);
                }

                resolve(
                  assets.sort(
                    (a, b) =>
                      parseInt(a.asset.prefs.node, 10) -
                      parseInt(b.asset.prefs.node, 10),
                  ),
                );
              }
            } else {
              reject(result);
            }
          })
          .catch((err) => reject(err));
      };
      return new Promise(getSlides);
    },
    getRecommendedSlides(slideIds) {
      const recommendSlides = (resolve, reject) => {
        getSlideDetail({
          assetIds: slideIds.map((i) => i.id),
          getMeta: true,
        })
          .then((resp) => {
            const { data } = resp;
            const recSlides = [];
            Object.keys(data).forEach(async (key) => {
              const { meta } = data[key];
              if (meta) {
                meta.landscapePath = await getFileURL(
                  this.currentUser.user.cognitoID,
                  meta.landscape,
                  this.currentUser.userIp,
                );
                meta.thumbnailPath = await getFileURL(
                  this.currentUser.user.cognitoID,
                  meta.thumbnail,
                  this.currentUser.userIp,
                );
                recSlides.push(meta);
              }
            });
            resolve(recSlides);
            // this.setRelatedSlides(recSlides);
          })
          .catch((err) => {
            reject(err);
          });
      };
      return new Promise(recommendSlides);
    },
    reportErrorRequest(uuid, failedReason) {
      errorRequestLog({
        uuid,
        failedReason,
      }).then((resp) => {
        console.log(resp);
      });
    },
    async getSlideInfo(slide) {
      const response = await checkGSStatus(slide.job_callbak_id, (interval) => {
        this.pollingInterval.push(interval);
      });
      return this.getSlideDetails(slide, {
        ...response.outputs,
        execution_end: response.execution_end,
        execution_start: response.execution_start,
      });
    },
    async getSlideDetails(slide, response) {
      const slideInfo = {
        ...slide,
        ...response.SlideAggregator.output,
        execution_end: response.execution_end,
        execution_start: response.execution_start,
      };
      const landscape = await retryOperation(
        () =>
          getFileURL(
            this.currentUser.user.cognitoID,
            slideInfo.slide_thumbnail,
            this.currentUser.userIp,
            'IOCUpload',
          ),
        2000,
        3,
      );
      slideInfo.landscape = landscape;
      const slideId = slideInfo.slide_source;
      const slideResp = await retryOperation(
        () => getSlideDataForDetailView(slideId),
        2000,
        3,
      );
      const genSlide = slideResp.data;
      // this.setGenerateSlide(slide);
      slideInfo.slideDetails = genSlide;

      const uniqueId =
        genSlide.parent && genSlide.parent.unique_id
          ? genSlide.parent.unique_id
          : slideId;
      const sid =
        genSlide.parent && genSlide.parent.sid
          ? genSlide.parent.sid
          : this.changeReq.slide.parent.sid;
      const siblings = await retryOperation(
        () => this.getSiblings(uniqueId, sid),
        2000,
        3,
      );
      slideInfo.siblings = siblings.filter((i) =>
        ['process', 'timeline'].includes(slideInfo.slide_type)
          ? i.asset.prefs.node > 2 && i.asset.prefs.node <= 12
          : true,
      );
      const reccos = await retryOperation(
        () => this.getRecommendedSlides(slideInfo.slide_recommendations),
        2000,
        3,
      );
      slideInfo.relatedSlides = reccos;

      return slideInfo;
    },
    async generateSlide() {
      this.generatingNewSlide = true;
      const changeReq = { ...this.changeReq };
      const selectedSlideID = this.selectedSlide.id;
      const targetSlide = this.slides.find((i) => i.id === selectedSlideID);
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        !this.limitsInfoObj?.generate?.isLimitCrossed ||
        this.limitsInfoObj?.generate?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'generate' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
        targetSlide.completed = false;
        targetSlide.slideDetails = changeReq.slide || targetSlide.slideDetails;
        targetSlide.error = null;
        if (
          changeReq.type === 'context_change' &&
          changeReq.context.texts.text.length &&
          changeReq.context.texts.text !== targetSlide.context.texts?.text
        ) {
          const file = await this.createTextFile(changeReq.context.texts.text);
          changeReq.context.texts = {
            ...file,
            text: changeReq.context.texts.text,
          };
        }
        const body = await this.getGenerateDeckRequest(changeReq);
        generateSlideRequest(body).then(() => {
          targetSlide.completed = false;
          targetSlide.slideDetails =
            changeReq.slide || targetSlide.slideDetails;
          targetSlide.error = null;
          targetSlide.job_callbak_id = null;
          checkGSStatus(body.callback_id, (interval) => {
            this.pollingInterval.push(interval);
          })
            .then(async (resp) => {
              const jobID =
                resp.outputs.SlideTrigger.events[0].slides[0].job_callbak_id;
              if (this.slides.length === 1) {
                this.executionTime = {
                  execution_start: resp.execution_start,
                  execution_end: resp.execution_end,
                };
                generateSlideRequestLog({
                  prompt: this.promptText,
                  uuid: jobID,
                }).then(() => {});
              }
              const extractedImages =
                resp.outputs.SlideAggregator.output.extracted_images;
              extractedImages.forEach(async (image, index) => {
                if (!this.extractedImages.find((i) => i.s3_path === image)) {
                  const extImg = await getFileURL(
                    this.currentUser.user.cognitoID,
                    `/${image}`,
                    this.currentUser.userIp,
                  );
                  this.extractedImages.unshift({
                    id: index,
                    url: extImg,
                    s3_path: image,
                  });
                }
              });
              targetSlide.job_callbak_id = jobID;
              const links =
                resp.outputs.SlideAggregator.output.processed_files?.links ||
                changeReq.context.links;
              // const slideInfo = {
              //   ...targetSlide,
              //   ...resp.outputs.SlideTrigger.events[0].slides[0],
              // };
              // console.log(slideInfo, 'slideInfoo');

              try {
                const slide = await this.getSlideInfo(targetSlide);
                const ind = this.slides.findIndex(
                  (j) => j.id === selectedSlideID,
                );
                this.slides[ind] = { ...slide };
                Vue.set(this.slides, ind, {
                  ...slide,
                  extractedImages,
                  completed: true,
                  error: false,
                  ...(changeReq.type === 'context_change' && {
                    context: { ...changeReq.context, links },
                  }),
                  lastSuccessfulSlide: {
                    ...slide,
                    completed: true,
                    error: false,
                    ...(changeReq.type === 'context_change' && {
                      context: { ...changeReq.context, links },
                    }),
                  },
                });

                this.postSlideLog(changeReq.type, this.slides[ind]);
                this.generatingNewSlide = false;
              } catch (err) {
                console.log(err);
                const ind = this.slides.findIndex(
                  (j) => j.id === selectedSlideID,
                );
                Vue.set(this.slides, ind, {
                  ...this.slides[ind],
                  completed: true,
                  error: true,
                  job_callbak_id: jobID,
                });
                this.selectedSlideID = this.slides[ind].id;
                this.generatingNewSlide = false;
                this.postSlideLog(changeReq.type, this.slides[ind]);
              }
            })
            .catch((err) => {
              console.log(err);
              const ind = this.slides.findIndex(
                (j) => j.id === selectedSlideID,
              );
              Vue.set(this.slides, ind, {
                ...this.slides[ind],
                completed: true,
                error: err.log === 'sb-toxicity' ? 'TOXICERR' : true,
              });
            });
        });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      }
    },
    getGenerateLog(changeReq) {
      const nonErrSlides = this.slides.filter((i) => !i.error);
      let maxExecTime = moment.utc(
        this.executionTime.execution_end,
        'DD/MM/YY HH:mm:ss:SSSSSS',
      );
      this.slides.forEach((slide) => {
        if (
          slide.execution_end &&
          (!maxExecTime ||
            moment.utc(slide.execution_end, 'DD/MM/YY HH:mm:ss:SSSSSS') >
              maxExecTime)
        ) {
          maxExecTime = moment.utc(
            slide.execution_end,
            'DD/MM/YY HH:mm:ss:SSSSSS',
          );
        }
      });
      return {
        uuid:
          this.slides.length === 1 &&
          changeReq &&
          changeReq.type &&
          [
            'slide_change',
            'node_change',
            'context_change',
            'replaceVisuals',
          ].includes(changeReq.type)
            ? this.slides[0].job_callbak_id
            : this.uuid,
        audienceID: this.audience?.id,
        templateCode: this.template,
        name: this.filename,
        description: this.description,
        execution_start: moment.utc(
          this.executionTime.execution_start,
          'DD/MM/YY HH:mm:ss:SSSSSS',
        ),
        execution_end: maxExecTime,
        generateType: this.generateType,
        extractedImages: this.extractedImages.map((i) => i.s3_path),
        status:
          // eslint-disable-next-line no-nested-ternary
          nonErrSlides.length === 0
            ? 'Failed'
            : this.slides.length === nonErrSlides.length
            ? 'Success'
            : 'Partial', // Failed, Partail
        operation: changeReq ? changeReq.type : '', // TEMPLATE_CHANGED, AUDIENCE_CHANGED
        promptCategory: !changeReq ? this.proTip : null,
        slideMeta: {
          slides: this.slides.map((i) =>
            i.error
              ? {
                  uuid: i.job_callbak_id,
                  status: 'failed',
                }
              : {
                  execution_end: moment.utc(
                    i.execution_end,
                    'DD/MM/YY HH:mm:ss:SSSSSS',
                  ),
                  execution_start: moment.utc(
                    i.execution_start,
                    'DD/MM/YY HH:mm:ss:SSSSSS',
                  ),
                  slideTitle: i.slide_title,
                  slideType: i.slide_type,
                  uuid: i.job_callbak_id,
                  uniqueID: i.slideDetails.unique_id,
                  category: i.slideDetails.categories[0],
                  construct: i.slideDetails.constructs[0],
                  assetID: i.slideDetails.unique_id,
                  s3: i.slide_path.startsWith('/newImageReplaceOutput/output/')
                    ? calyrexConfig.calyrex_bucket_name
                    : process.env.VUE_APP_MVP_ASSETS_BUCKET,
                  fileName: i.slide_path,
                  thumbnail: i.slide_thumbnail,
                  node: i.slide_node_count,
                  image: i.image_keyword,
                  status: 'success',
                },
          ),
        },
        ...(!changeReq && {
          files: this.getDeckContext.files,
          texts: [(({ text, ...o }) => o)(this.getDeckContext.texts)],
          links: this.getDeckContext.links,
        }),
        mlResponse: {
          json: {},
        },
      };
    },
    async generateDeck(promptSuggestion = false) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
          this.checkingUsage = false;
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.generate?.isLimitCrossed ||
        this.limitsInfoObj?.generate?.isFullAccess
      ) {
        postFeatureUsage({ feature: 'generate' })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));
        this.form = {
          isLiked: null,
          feedback: '',
        };
        this.imageRendered = false;
        // this.disableActions = true;
        this.selectedSlideID = null;
        this.closeSmartTool();
        const body = await this.getGenerateDeckRequest(
          this.changeReq,
          promptSuggestion,
        );
        this.slides = [];
        this.loading = true;
        this.uuid = body.callback_id;
        this.error = null;
        console.log(body);
        generateSlideRequest(body)
          .then(() => {
            generateSlideRequestLog({
              prompt: this.promptText,
              uuid: body.callback_id,
              files: this.getDeckContext.files,
              texts: (({ text, ...o }) => o)(this.getDeckContext.texts),
              links: this.getDeckContext.links,
              suggestionsIgnored: this.suggestionStatus
                ? this.suggestionStatus.ignored
                : undefined,
              referenceId: this.suggestionStatus
                ? this.suggestionStatus.referenceId
                : undefined,
            }).then((res) => {
              this.promptDropdownHistory = res?.data.prompts || [];
            });
            if (!promptSuggestion) {
              this.suggestionStatus = null;
            }
            this.handleDeck(body.callback_id);
          })
          .catch(() => {
            if (this.slides.length) {
              this.loading = false;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
              this.reportErrorRequest(this.uuid, 'Prompt_Error');
            }
          });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'generate' });
      }
    },
    handleDeck(id) {
      checkGSStatus(
        id,
        (interval) => {
          this.uuid = id;
          this.pollingInterval.push(interval);
        },
        (res) => {
          try {
            const slideOutput = res.outputs.SlideTrigger.events;
            if (slideOutput && slideOutput.length) {
              if (this.slides.length === 0) {
                const slideCount = slideOutput[0].slides[0].b_total_slides;
                this.slides = Array.from(Array(slideCount)).map((i, ind) => ({
                  ...i,
                  slide_index: ind,
                  id: uuidv4(),
                  completed: false,
                  siblings: [],
                  relatedSlides: [],
                  context: {
                    texts: { text: '' },
                    files: [],
                    links: [],
                    instructions: '',
                    extractedImages: [],
                    extract_graph_data: false,
                  },
                }));
                this.selectedSlideID = this.slides[0].id;
                this.loading = false;
              }
              const slides = slideOutput.map((i) => i.slides).flat();
              slides.forEach(async (sld) => {
                const slideIndex = sld.slide_index;
                if (
                  !this.slides[slideIndex].job_callbak_id &&
                  !this.slides[slideIndex].processing
                ) {
                  const ind = this.slides.findIndex(
                    (j) => j.slide_index === slideIndex,
                  );
                  this.slideCallbackIds.push(sld);
                  Vue.set(this.slides, ind, {
                    ...this.slides[ind],
                    job_callbak_id: sld.job_callbak_id,
                    processing: true,
                  });
                }
              });
            }
          } catch (err) {
            console.log(err);
            if (this.slides.length) {
              this.loading = false;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
              this.reportErrorRequest(this.uuid, 'Prompt_Error');
            }
          }
        },
      )
        .then(async (resp) => {
          console.log(resp.outputs);
          try {
            const { meta } = resp.outputs.SlideAggregator.output;
            this.filename = meta.filename;
            this.description = meta.description;
            this.animateProTip =
              (meta.prompt_category || 'none_of_the_above') !== this.proTip;
            this.proTip = meta.prompt_category || 'none_of_the_above';
            this.executionTime = {
              execution_start: resp.execution_start,
              execution_end: resp.execution_end,
            };
            this.generateType = meta.generation_type;
            const extractedImages =
              resp.outputs.SlideAggregator.output.extracted_images;
            extractedImages.forEach(async (image, index) => {
              if (!this.extractedImages.find((i) => i.s3_path === image)) {
                const extImg = await getFileURL(
                  this.currentUser.user.cognitoID,
                  `/${image}`,
                  this.currentUser.userIp,
                );
                this.extractedImages.push({
                  id: index,
                  url: extImg,
                  s3_path: image,
                });
              }
            });
            this.setDeckContext({
              ...this.getDeckContext,
              links:
                resp.outputs.SlideAggregator.output.processed_files?.links ||
                this.getDeckContext.links,
            });
            const { audience } = resp.outputs.SlideAggregator.output;
            this.audience = {
              id: audience.audience_id,
              type: audience.audience_type,
              num_id: audience.audience_num_id,
              ownerID: audience.audience_owner_id,
            };
            this.template =
              resp.outputs.SlideAggregator.output.template.template_code;
          } catch (err) {
            console.log(err);
            if (this.slides.length) {
              this.loading = false;
              this.cancelRequest();
            } else {
              this.error = 'PROMPTERR';
              this.loading = false;
              this.reportErrorRequest(this.uuid, 'Prompt_Error');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (this.slides.length) {
            this.loading = false;
            this.cancelRequest();
          } else if (err.log === 'sb-suggestions') {
            this.loading = false;
            this.liveSuggestions = [...err.liveSuggestions];
            const payload = {
              promptSuggestions: this.liveSuggestions,
              uuid: this.uuid,
              status: 'PromptSuggested',
              promptCategory: err.promptCategory,
            };
            generateSlideCompleteLog(payload).then((res) => {
              console.log(res, 'complete log');
            });
            this.showLiveProTip = true;
          } else {
            this.error = err.log === 'sb-toxicity' ? 'TOXICERR' : 'PROMPTERR';
            this.loading = false;
            this.reportErrorRequest(this.uuid, 'Prompt_Error');
          }
        });
    },
    async createTextFile(text) {
      const id = uuidv4();
      let txt = {
        file: new Blob([text], { type: 'text/plain' }),
        name: `text_${id}.txt`,
        path: `text_${id}`,
        requestIdentifier: id,
      };
      await uploadChunks(txt, 'generate');
      await uploadOPFile(txt.requestIdentifier, txt.name, 'generate').then(
        (res) => {
          txt = {
            ...txt,
            ...res.data,
            processingCompleted: true,
          };
        },
      );
      return {
        text_id: txt.requestIdentifier,
        file_name: txt.name,
        file_type: 'text/plain',
        s3_path: txt.s3Prefix,
        s3_bucket: txt.s3Bucket,
        source: 'upload',
      };
    },
    // getGenerateSlideRequest() {
    //   const uuid = uuidv4();
    //   const req = {
    //     callback_id: uuid,
    //     report_id: uuid,
    //     workflow_name: 'sb',
    //     workflow_context: {
    //       operation: this.changeReq.type,
    //       prompt: this.promptText,
    //       override: {
    //         workflow: this.selectedSlide.job_callbak_id,
    //         ...(this.changeReq.type === 'node_change' && {
    //           node_count: parseInt(this.changeReq.slide.prefs.node, 10),
    //         }),
    //         ...((this.changeReq.type === 'slide_change' ||
    //           this.changeReq.type === 'node_change') && {
    //           slide: this.changeReq.slide.unique_id,
    //         }),
    //         ...(this.changeReq.type === 'context_change' && {
    //           context: {
    //             context: this.changeReq.prompt,
    //           },
    //         }),
    //       },
    //     },
    //   };
    //   return req;
    // },
    async getGenerateDeckRequest(changeReq, promptSuggestion = false) {
      const uuid = uuidv4();
      // eslint-disable-next-line no-nested-ternary
      const context = !changeReq
        ? this.getDeckContext
        : changeReq.type === 'add_new_slide'
        ? changeReq.context
        : {};
      const req = {
        callback_id: uuid,
        report_id: uuid,
        workflow_name: 'db',
        workflow_context: {
          operation: changeReq ? changeReq.type : 'new_generation',
          prompt: this.promptText,
          userID: this.currentUser.user.id,
          requesterCompanyCode: this.currentUser.company.name,
          template_code: this.template || this.currentUser.theme.code,
          userNumID: this.currentUser.user.num_id,
          teamNumID: this.currentUser.team.num_id,
          companyNumID: this.currentUser.company.num_id,
          prompt_suggestions:
            this.getAGLivePromptAccess &&
            this.getAGLiveSuggestionEnabled &&
            promptSuggestion,
          override: {
            workflow:
              this.selectedSlide && this.selectedSlide.slide_callback_id
                ? this.selectedSlide.slide_callback_id
                : this.uuid,
            audience: this.audience,
            template: { template: this.template },
            ...(changeReq &&
              changeReq.noOfSlides && {
                duration: {
                  no_of_slides: changeReq.noOfSlides,
                },
              }),
            ...(changeReq &&
              ['node_change', 'slide_change', 'context_change'].includes(
                changeReq.type,
              ) && {
                slide_override: {
                  workflow: this.selectedSlide.lastSuccessfulSlide
                    ? this.selectedSlide.lastSuccessfulSlide.job_callbak_id
                    : this.selectedSlide.job_callbak_id,
                  ...(changeReq.type === 'node_change' && {
                    node_count: parseInt(changeReq.slide.prefs.node, 10),
                  }),
                  ...((changeReq.type === 'slide_change' ||
                    changeReq.type === 'node_change') && {
                    slide: changeReq.slide.unique_id,
                  }),
                  ...(changeReq.type === 'context_change' && {
                    context: {
                      context: changeReq.context.instructions,
                      extract_graph_data: changeReq.context.extract_graph_data,
                      ...(changeReq.context.files?.length && {
                        files: changeReq.context.files.map((file) => ({
                          file_id: file.fileID,
                          file_name: file.fileName,
                          file_type: file.type,
                          s3_path: file.s3Prefix,
                          s3_bucket: file.s3Bucket,
                          source: 'upload',
                        })),
                      }),
                      ...(changeReq.context.links?.length && {
                        links: changeReq.context.links,
                      }),
                      ...(changeReq.context.texts?.text_id && {
                        texts: [
                          {
                            text_id: changeReq.context.texts.text_id,
                            file_name: changeReq.context.texts.file_name,
                            file_type: 'text/plain',
                            s3_path: changeReq.context.texts.s3_path,
                            s3_bucket: changeReq.context.texts.s3_bucket,
                            source: 'upload',
                          },
                        ],
                      }),
                    },
                  }),
                },
                slde_index: this.selectedSlide.slide_index || 0,
              }),
            ...(changeReq &&
              ['audience_change', 'template_change'].includes(
                changeReq.type,
              ) && {
                keep_slides: this.slides
                  .filter((i) => !i.error)
                  .map((i) => ({
                    workflow: i.job_callbak_id,
                  })),
              }),
          },
          ...(context.extract_graph_data !== undefined && {
            extract_graph_data: context.extract_graph_data,
          }),
          ...(context.files?.length && {
            files: context.files.map((file) => ({
              file_id: file.fileID,
              file_name: file.fileName,
              file_type: file.type,
              s3_path: file.s3Prefix,
              s3_bucket: file.s3Bucket,
              source: 'upload',
            })),
          }),
          ...(context.links?.length && {
            links: context.links,
          }),
          ...(context.texts?.text_id && {
            texts: [
              {
                text_id: context.texts.text_id,
                file_name: context.texts.file_name,
                file_type: 'text/plain',
                s3_path: context.texts.s3_path,
                s3_bucket: context.texts.s3_bucket,
                source: 'upload',
              },
            ],
          }),
        },
      };

      return req;
    },
    generateSuggestion({ ignore, prompt }) {
      this.suggestionStatus = {
        ignored: ignore,
        referenceId: this.uuid,
      };
      if (ignore) {
        this.showLiveProTip = false;
        this.generate(true, false);
      } else {
        this.showLiveProTip = false;
        this.prompMsg = prompt;
        this.generate(true, false);
      }
    },
    async generate(isOnGenClick, promptSuggestion = false) {
      if (isOnGenClick) {
        this.startTime = performance.now();
      }
      this.uuid = null;
      this.changeReq = null;
      this.setPromptText(this.prompMsg);
      this.saveDeckInfo();
      this.resetSlideLibraryFlags();
      this.audience = null;
      this.template = null;
      this.extractedImages = [];
      this.checkingUsage = true;
      this.replacingImage = false;
      this.isPromptBoxFocused = false;
      if (this.getDeckContext.texts.text.length) {
        const file = await this.createTextFile(this.getDeckContext.texts.text);
        console.log(file);
        this.setDeckContext({
          ...this.getDeckContext,
          texts: {
            ...file,
            text: this.getDeckContext.texts.text,
          },
        });
      }
      this.generateDeck(promptSuggestion);
      if (isOnGenClick) {
        trackGenerateEvents.generatePromptButtonClick(this.currentUser, {
          [TD_INSTRUCTIONS]: this.prompMsg || '',
          [TD_COMMON_COLUMN_NAME]: 'Context added',
          [TD_COMMON_COLUMN_VALUE]: this.getAGMatomoContext(
            this.getDeckContext,
          ),
        });
      }
    },

    formatProperty(str) {
      return str.charAt(0) === '/' ? str : `/${str}`;
    },

    async addToFavorite() {
      if (this.addedToLibraryData.type === 'slide') {
        const favoriteData = {
          id: uuidv4(),
          assetID: this.addedToLibraryData.info.unique_id,
          source: this.addedToLibraryData.info.tags.source,
          themeID: this.template,
          tailorID: this.audience?.id,
          audienceType: this.audience?.type,
        };
        await createFavorite(favoriteData)
          .then(() => {
            this.postCompleteLog('favorite', true);
            this.isAddedAsFavorite = true;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      } else {
        await addPrezentationAsFavorite({
          prezentationId: this.addedToLibraryData.info.prezentationId,
        })
          .then(() => {
            this.postCompleteLog('favorite', true);
            this.isAddedAsFavorite = true;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      }
    },

    postCompleteLog(type, val) {
      const payload = {
        uuid: this.uuid,
        type,
        value: val,
      };
      generateSlidePostCompleteLog(payload).then((resp) => {
        console.log(resp);
      });
    },

    async updateAddedToSlideLibraryStatus(name, addFavorite = false) {
      this.generateOperation.loading = true;
      if (addFavorite)
        trackGenerateEvents.generateFavoriteClick(this.currentUser, {
          [TD_ISFAVORITED]: 1,
          [TD_NODECOUNT]: this.selectedSlide?.slideDetails?.prefs?.node || '',
          [TD_SLIDE_NAME]: this.filename || '',
        });
      else
        trackGenerateEvents.generateAddToSlideLibraryClick(this.currentUser, {
          [TD_NODECOUNT]: this.selectedSlide?.slideDetails?.prefs?.node || '',
          [TD_SLIDE_NAME]: this.filename || '',
        });
      if (this.isAddedToLibrary && addFavorite) {
        await this.addToFavorite();
        this.setAddedToLibOrFavSnackbar({
          show: true,
          ctaText: 'snackBarItems.goToFavorites',
          snackbarText:
            this.slides.length > 1 || this.lastSuccessfulDeck?.slides.length > 1
              ? 'snackBarItems.prezAddedToFav'
              : 'snackBarItems.slideAddedToFav',
          navFn: this.goToFavLibrary,
          snackbarType: 'goToFav',
        });
        return;
      }
      this.modifiedFileName = name;
      if (
        this.slides.length > 1 ||
        this.lastSuccessfulDeck?.slides.length > 1
      ) {
        let nonErrSlides = [];
        if (!this.slides.length && this.lastSuccessfulDeck?.slides.length) {
          nonErrSlides = this.lastSuccessfulDeck.slides.filter(
            (i) => !i.error && i.completed,
          );
        } else {
          nonErrSlides = this.slides.filter((i) => !i.error && i.completed);
        }
        const payload = {
          uuid: this.uuid,
          name,
          description: this.description,
          audienceID: this.audience.id,
          audienceOwnerID: this.audience.ownerID,
          templateCode: this.template,
          slideMeta: {
            slides: nonErrSlides.map((i) => ({
              uniqueID: i.slideDetails.unique_id,
              category: i.slideDetails.categories[0],
              construct: i.slideDetails.constructs[0],
              assetID: i.slide_thumbnail,
              fileName: i.slide_path,
              thumbnail: i.slide_thumbnail,
              node: i.slide_node_count,
              mlTags: {
                uniqueID: i.slideDetails?.unique_id,
                node: i.slide_node_count,
                construct: i.slideDetails?.constructs.join(', ') || '',
                ...i.slideDetails?.prefs,
                source: 'generated',
              },
            })),
          },
        };
        // this.generateOperation.loading = true;
        await addToLibrary(payload)
          .then(async (resp) => {
            this.postCompleteLog('added_to_library', true);
            this.addedToLibraryData = {
              info: resp.data,
              type: 'prezentation',
            };
            this.prezOrSlideId = resp.data.prezentationId;
            this.isAddedToLibrary = true;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            if (addFavorite) {
              await this.addToFavorite();
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.prezAddedToFav',
                navFn: this.goToFavLibrary,
                snackbarType: 'goToFav',
              });
            } else {
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToLibrary',
                snackbarText: 'snackBarItems.prezAddedToLib',
                navFn: this.goToLibrary,
                snackbarType: 'goToLib',
              });
            }
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      } else {
        this.generateOperation.loading = true;
        let slides = [];
        if (!this.slides.length && this.lastSuccessfulDeck?.slides.length) {
          slides = [...this.lastSuccessfulDeck.slides];
        } else {
          slides = [...this.slides];
        }
        const payload = {
          source: 'generated',
          isShare: false,
          sharedEntities: [],
          slidesData: [
            {
              tags: {
                uniqueID: slides[0].slideDetails?.unique_id,
                node: slides[0].slide_node_count,
                construct: slides[0].slideDetails?.constructs.join(', ') || '',
                ...slides[0].slideDetails?.prefs,
                source: 'generated',
              },
              categories: slides[0].slideDetails?.categories || [],
              constructs: slides[0].slideDetails?.constructs.join(', ') || '',
              title: name,
              summary: slides[0].slideDetails?.summary || '',
              filename: slides[0].slide_path,
              landscape: this.formatProperty(slides[0].slide_thumbnail),
              thumbnail: this.formatProperty(slides[0].slide_thumbnail),
              type: 'generated',
              templateCode: this.template || '',
              audience: this.audience.id,
              audienceNumId: Number(this.audience.num_id),
              audienceType: this.audience.type,
            },
          ],
        };
        await createSlide(payload)
          .then(async (resp) => {
            this.postCompleteLog('added_to_library', true);
            this.addedToLibraryData = {
              info: resp.data[0],
              type: 'slide',
            };
            this.isAddedToLibrary = true;
            this.prezOrSlideId = resp.data[0].id;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            if (addFavorite) {
              await this.addToFavorite();
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToFavorites',
                snackbarText: 'snackBarItems.slideAddedToFav',
                navFn: this.goToFavLibrary,
                snackbarType: 'goToFav',
              });
            } else {
              this.setAddedToLibOrFavSnackbar({
                show: true,
                ctaText: 'snackBarItems.goToLibrary',
                snackbarText: 'snackBarItems.slideAddedToLib',
                navFn: this.goToLibrary,
                snackbarType: 'goToLib',
              });
            }
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      }
    },

    handleAddToSlideLibrary() {
      if (this.isAddedToLibrary) {
        // this.isAddedToLibraryDownload = false;
        // this.resetAddedToLibrarySnackbarState();
        console.log('remove from library');
        if (this.addedToLibraryData.type === 'slide') {
          this.removingFromLibrary = true;
          deleteSlide(this.addedToLibraryData.info.unique_id)
            .then(() => {
              this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.removingFromLibrary = true;
          removeFromLibrary(this.addedToLibraryData.info.prezentationId).then(
            () => {
              this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            },
          );
        }
      } else {
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.slides.length > 1
                ? this.$t('generateToolBar.add_to_prez_exchange')
                : this.$t('generateToolBar.add_to_slide_library'),
            primaryBtnText: this.$t('premiumFeature.addToLibrary'),
            modalType: 'slidesLibrary',
            type: this.slides.length > 1 ? 'prezentation' : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            operation: this.generateOperation,
            filename: this.modifiedFileName || this.filename,
            originPage: 'generated',
            getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
            logDownload: this.handleDownloadLog,
            closePreviousPopups: this.handleClosePopAndSaveToLib,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },

    async handleAddToFavorites() {
      if (this.isAddedAsFavorite) {
        if (this.addedToLibraryData.type === 'slide') {
          this.removingAsFavorite = true;
          const params = {
            assetIds: [this.addedToLibraryData.info.unique_id],
            getFavourite: true,
          };
          const resp = await getSlideDetail(params);
          const favSlideId =
            resp.data[this.addedToLibraryData.info.unique_id].favouriteId;
          await deleteFavorite(favSlideId)
            .then(() => {
              this.postCompleteLog('favorite', false);
              this.isAddedAsFavorite = false;
              this.removingAsFavorite = false;
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => console.log(err));
        } else {
          this.removingAsFavorite = true;
          removePrezentationAsFavorite(
            this.addedToLibraryData.info.prezentationId,
          ).then(() => {
            this.postCompleteLog('favorite', false);
            this.isAddedAsFavorite = false;
            this.removingAsFavorite = false;
            // this.$modal.hide('GenerateActionModal');
          });
        }
        console.log('removing favorites');
      } else {
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.slides.length > 1
                ? this.$t('premiumFeature.addPrezentationToFavourites')
                : this.$t('premiumFeature.addSlideToFavourites'),
            primaryBtnText: this.$t('slideDetails.addFavorite'),
            modalType: 'favorites',
            type: this.slides.length > 1 ? 'prezentation' : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            isAddedToLibrary: this.isAddedToLibrary,
            operation: this.generateOperation,
            filename: this.modifiedFileName || this.filename,
            originPage: 'generated',
            getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
            logDownload: this.handleDownloadLog,
            closePreviousPopups: this.handleClosePopAndSaveToLib,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },

    getMainHeadingWidth() {
      if (window.innerWidth <= 1020) {
        this.mainHeadingWidth = 20;
      } else if (window.innerWidth > 1020 && window.innerWidth <= 1110) {
        this.mainHeadingWidth = 30;
      } else if (window.innerWidth > 1110 && window.innerWidth <= 1200) {
        this.mainHeadingWidth = 40;
      } else if (window.innerWidth > 1200 && window.innerWidth <= 1300) {
        this.mainHeadingWidth = 50;
      } else if (window.innerWidth > 1300 && window.innerWidth <= 1400) {
        this.mainHeadingWidth = 60;
      } else if (window.innerWidth > 1400 && window.innerWidth <= 1500) {
        this.mainHeadingWidth = 70;
      } else if (window.innerWidth > 1500 && window.innerWidth <= 1600) {
        this.mainHeadingWidth = 80;
      } else if (window.innerWidth > 1600 && window.innerWidth <= 1700) {
        this.mainHeadingWidth = 90;
      } else if (window.innerWidth > 1700 && window.innerWidth <= 1800) {
        this.mainHeadingWidth = 100;
      } else {
        this.mainHeadingWidth = 100;
      }
    },

    getPromptDropdwonWidthHeight() {
      if (this.$refs.promptBoxRef) {
        this.promptDropdownWidth = this.$refs.promptBoxRef.offsetWidth;
        this.promptDropdownHeight = this.$refs.promptBoxRef.offsetHeight;
      } else {
        this.promptDropdownWidth = 0;
        this.promptDropdownHeight = 0;
      }
      this.getMainHeadingWidth();
    },

    handlePromptDropdownItemSelect(item) {
      this.prompMsg = item;
    },

    loadPromptDropdownContent() {
      getPromptDropdownHistoryOnLoad()
        .then((resp) => {
          if (resp.data && resp.data.prompts) {
            this.promptDropdownHistory = resp.data.prompts;
          } else {
            this.promptDropdownHistory = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      this.slides.forEach((slide) => {
        if (slide.completed && !slide.error) {
          s.slides.push({
            slideId:
              slide?.slide_path?.charAt(0) === '/'
                ? slide?.slide_path?.substring(1)
                : slide?.slide_path,
            isReplaced:
              slide?.slide_path?.startsWith('/newImageReplaceOutput/output/') ||
              slide?.slide_path?.startsWith('/output/pptx/'),
            isUploaded: false,
            isGenerated: true,
            isSynthesis: false,
            isRedesign: false,
            isComply: false,
          });
        }
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },
    async handleClosePopAndSaveToLib(name, addToLib) {
      if (addToLib === true && !this.isAddedToLibrary) {
        await this.updateAddedToSlideLibraryStatus(name);
        // this.isAddedToLibraryDownload = true;
        // this.setIsAddedToLibraryDownload(true);
        // this.setPremiumFtLibConfPopupData({
        //   message:
        //     this.slides.length > 1
        //       ? this.$t('premiumFeature.addedToPrezLibrary')
        //       : this.$t('premiumFeature.addedToSlideLibrary'),
        //   btnText: this.$t('premiumFeature.openInLibrary'),
        //   goToLibrary: this.goToLibrary,
        //   cancel: this.closePopup,
        // });
        // setTimeout(() => {
        //   // this.isAddedToLibraryDownload = false;
        //   this.resetAddedToLibrarySnackbarState();
        // }, 10000);
      }
      this.$modal.hide('GenerateActionModal');
    },
    isPendoWalkthroughRunning() {
      if (
        window.pendo &&
        window.pendo.guide &&
        window.pendo.guide.isGuideActive
      ) {
        const activeGuide = window.pendo.getActiveGuide();
        if (activeGuide) {
          return true;
        }
      }
      return false;
    },
    handleVisibility() {
      if (this.processInProgress && document.visibilityState === 'hidden') {
        const endTime = performance.now();
        const timeSpent = Math.round((endTime - this.startTime) / 1000);
        const otherData = {
          [TD_TIME_SPENT_IN_SECS]: timeSpent,
        };
        trackGenerateEvents.generateUserTabSwitch(this.currentUser, otherData);
      }
    },
  },
  mounted() {
    if (
      !this.isPendoWalkthroughRunning() &&
      this.allowAgDayZeroPopup &&
      this.currentUser?.user?.agDayZeroPopUp === 0
    ) {
      this.displayDayZeroPopup();
    }
    if (this.$route.query.prompt) {
      this.prompMsg = this.$route.query.prompt;
    }
    if (!this.isGenerateFullAccess) {
      this.$router.push('/home', () => {});
    }
    this.resetState();
    this.loadPromptDropdownContent();
    this.getMainHeadingWidth();
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.closeSmartTool();
    });
    this.$nextTick(() => {
      this.openRightPanel('promptExamples');
    });
    // this.getRandomSlide();
    if (this.$refs.promptBoxRef) {
      this.promptDropdownWidth = this.$refs.promptBoxRef.offsetWidth;
      this.promptDropdownHeight = this.$refs.promptBoxRef.offsetHeight;
    }
    window.addEventListener('resize', this.getPromptDropdwonWidthHeight);
    document.addEventListener('visibilitychange', this.handleVisibility, false);
  },
  beforeDestroy() {
    this.slideCallbackIds = [];
    this.statusApiInProgress = false;
    window.removeEventListener('resize', this.getPromptDropdwonWidth);
    if (this.uuid && this.slides.length === 0) {
      cancelGSRequest(this.uuid).then((resp) => {
        console.log(resp);
      });
    }
    if (this.slides.length) {
      this.slides.forEach((slide) => {
        if (!slide.completed && slide.job_callbak_id) {
          cancelGSRequest(slide.job_callbak_id).then((resp) => {
            console.log(resp);
          });
        }
      });
    }
    this.pollingInterval.forEach((i) => {
      clearInterval(i);
    });
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
    // this.resetAddedToLibrarySnackbarState();
  },
  beforeRouteLeave(to, from, next) {
    if (
      !this.isAddedToLibrary &&
      ((this.slides && this.slides.length) ||
        (this.lastSuccessfulDeck && this.lastSuccessfulDeck.slides.length)) &&
      this.deckValid
    ) {
      this.pageChangeAction(next);
    } else {
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
.loading-spinner {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tutorial-menu {
  border-radius: 12px;
  width: 520px;
  background: white;
}
.context-prompt-menu {
  border-radius: 12px;
  overflow: visible;
  contain: none;
  width: 460px;
}
::v-deep button.v-btn:disabled {
  icon {
    color: #757575;
  }
}

.generate-rating-btn-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 12px;

  .rating-disabled {
    opacity: 0.8;
    cursor: default;
  }
}
.generate-rating-popover {
  border-radius: 16px;

  .feedback-success {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: #000;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__content {
    padding: 22px;
    padding-bottom: 0;
    padding-top: 42px;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
.form {
  &__label {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    display: block;
  }
  &__action {
    padding: 22px;
    padding-top: 0;
  }
  &__textarea {
    border-radius: 8px;
  }
}
// .generate-banner {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   padding: 14px 8px 14px 16px;
//   gap: 4px;
//   height: 48px;
//   background: #e1f5fb;
//   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
//   border-radius: 4px;

//   .dismiss-btn {
//     cursor: pointer;
//   }
// }
.generate-slides-container {
  position: relative;
  display: flex;
  height: calc(100vh - 68px);

  .smart-tool-switch {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 4;
    background: white;

    .divider {
      border: 0.75px solid #b6b6b6;
    }
  }

  .fade {
    opacity: 0.4;
    pointer-events: none;
  }
  .right-panel {
    box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3);
    width: 550px;
    padding-right: 55px;
    transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
      transform 0.5s ease-in;
    &.hide {
      width: 0;
      transform: translateX(550px);
      opacity: 0;
      transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
        transform 0.5s ease-in;
    }
  }

  .cancel-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;

    width: 98px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #21a7e0;

    background-color: #ffffff;
    border: 1px solid #21a7e0;
    border-radius: 25px;
  }

  .generate-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
  .gs-prompt-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 1434px;
    width: 93%;
    margin: 0 auto;
    transition: width 0.5s ease-in;

    &.right-panel-open {
      position: relative;
      width: calc(100% - 550px);
      transition: width 0.5s ease-in;
    }

    // .heading {
    //   font-family: 'Lato';
    //   font-style: normal;
    //   font-weight: 600;
    //   font-size: 28px;
    //   line-height: 29px;
    //   color: #000000;

    //   .sub {
    //     font-family: 'Lato';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 12px;
    //     line-height: 14px;
    //     letter-spacing: 0.02em;
    //     color: #697077;
    //     margin-top: 4px;
    //   }

    //   .main {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //   }
    // }

    .gs-prompt {
      position: relative;
      min-height: 110px;
      z-index: 2;
      // margin-top: 8px;
      // margin-bottom: 16px;
      // padding: 10px 15px;
      // box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
      // border-radius: 6px;

      &.focus {
        .dynamic-box {
          position: absolute;
          width: 100%;
        }
        .text-input {
          ::v-deep textarea {
            max-height: 285px;
            overflow-y: auto;
          }
        }
      }

      &.blur {
        .text-input {
          ::v-deep textarea {
            max-height: 65px;
            overflow-y: auto;
          }
        }
      }

      .input {
        display: flex;
        gap: 20px;

        ::v-deep .v-input__slot {
          display: flex;
          align-items: center;

          .v-input__append-inner {
            // align-self: center;
            margin-top: 20px;
          }
          &::before,
          &::after {
            border: none !important;
          }
        }

        .text-input {
          padding-top: 0;
          font-size: 14px;
          color: #464a4c;
          margin-top: 0;

          ::v-deep .v-text-field__slot input::placeholder {
            color: #757575;
          }
          ::v-deep .v-text-field__slot input:disabled {
            color: #757575;
          }

          ::v-deep textarea {
            min-height: 65px;
            line-height: 1.5;

            &::placeholder {
              color: #697077;
            }
          }
        }

        .promptBoxCTAs {
          display: flex;
          justify-content: space-between;
          position: relative;
          height: 65px;
          align-items: center;
          .addContext {
            background-color: #ffffff !important;
            box-shadow: none;
            font-family: 'Lato';
            font-style: normal;
            letter-spacing: normal;
            text-transform: none;
            border-radius: 25px;
            color: #21a7e0;
            height: 32px;
            font-size: 16px;
            font-weight: 600 !important;
            margin: 0px 12px 0px 0px;
          }
          .cancel-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6px 16px;
            gap: 8px;

            width: 98px;
            height: 32px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-transform: capitalize;
            letter-spacing: normal;
            line-height: 19px;

            /* Color Styles (Old)/White */

            color: #21a7e0;

            background: #ffffff;
            border: 1px solid #21a7e0;
            border-radius: 25px;
          }
        }
      }

      .panel-items {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 5px;
        margin-top: 10px;

        > div {
          display: flex;
          gap: 10px;
          align-items: center;
          color: #21a7e0;
          cursor: pointer;
          padding: 8px;

          &.current {
            background: #e1f5fb;
            border-radius: 8px;
          }
          &.addContextDisabled {
            color: rgba(0, 0, 0, 0.26);
            pointer-events: none;
            border-radius: 8px;
            background-color: #ffffff;
            i {
              color: rgba(0, 0, 0, 0.26) !important;
            }
          }
        }
      }

      .pro-tip-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .promptDropdown {
      box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
      border-radius: 0px 0px 6px 6px;
      z-index: 2;
      position: absolute;
      padding: 8px 0px;
      background-color: #ffffff;
      top: 147px;
      .suggested,
      .history {
        .heading {
          display: flex;
          font-size: 12px;
          font-weight: 400;
          padding: 0px 15px;
          .v-icon {
            margin: 0px 4px 0px 0px;
          }
          div {
            font-size: 14px;
            color: grey;
            font-weight: 400;
            color: grey;
            margin: 0px 0px 4px 0px;
          }
        }
      }

      .suggested {
        .suggestedItemList {
          p {
            margin: 0px;
            padding: 8px 15px 8px 15px;
            cursor: pointer;
            &:hover {
              color: #21a7e0;
              background-color: rgba(0, 0, 0, 0.12);
            }
          }
        }
      }
      .history {
        padding: 16px 0px 0px 0px;
        .historyItemList {
          p {
            margin: 0px;
            padding: 8px 15px 8px 15px;
            cursor: pointer;
            &:hover {
              color: #21a7e0;
              background-color: rgba(0, 0, 0, 0.12);
            }
          }
        }
        .noHistoryPresent {
          font-style: italic;
          color: grey;
          padding: 0px 15px 10px 15px;
        }
      }
    }

    .slide-container {
      width: 100%;
      position: relative;
      display: flex;
      gap: 20px;
      flex: 1;
      min-height: 0;

      .slides-list {
        width: 364px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        height: 100%;
        overflow: auto;
        padding: 5px 10px 10px 0;
        transition: width 0.5s ease-in;
        position: relative;
        top: -5px;

        .example-slide-mini {
          width: 100%;
          display: flex;
          gap: 10px;

          .example-mini-image {
            position: relative;
            aspect-ratio: 16/9;
            width: calc(100% - 20px);
            box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
            border-radius: 12px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .slide-content {
        width: calc(100% - 384px);
        transition: width 0.5s ease-in;
      }

      &.panel-open {
        gap: 10px;

        .slides-list {
          width: 140px;
          transition: width 0.5s ease-in;

          .draggable {
            min-height: 54px;
            transition: all 0.5s ease-in;
            gap: 4px;

            .slide-small-thumbnail-container {
              box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1),
                1px 1px 4px 0px rgba(0, 0, 0, 0.1);
              border-radius: 2px;

              .slide-actions {
                padding: 2px 2px;
                > div {
                  display: flex;
                  gap: 0px;
                }
              }

              &:hover {
                .slide-actions {
                  height: 30px;
                }
              }
            }
          }
        }

        .slide-content {
          width: calc(100% - 150px);
          transition: width 0.5s ease-in;
        }
      }

      .drag-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .draggable {
        display: flex;
        gap: 8px;
        min-height: 180px;
        transition: all 0.5s ease-in;

        > div {
          min-width: 18px;
        }

        .slide-small-thumbnail-container {
          cursor: pointer;
          box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
          border-radius: 12px;
          overflow: hidden;
          width: calc(100% - 28px);
          // aspect-ratio: 16/9;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .slide-landscape {
            width: 100%;
            aspect-ratio: 16/9;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .slide-actions {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            opacity: 0;
            height: 0;
            transition: all 0.3s ease-in;
            transition-delay: 0.1s;

            > div {
              display: flex;
              gap: 16px;
            }
          }

          &:hover {
            .slide-actions {
              height: 48px;
              opacity: 1;
              transition: all 0.3s ease-in;
            }
          }
        }

        .selected {
          border: 1.5px solid #21a7e0;
        }
      }

      .landscape-slide {
        position: relative;
        box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
        border-radius: 12px;
        aspect-ratio: 16/9;
        margin-bottom: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        > div {
          width: 100%;
        }

        .error-img-container {
          position: relative;
          width: 100%;

          .landscape-actions {
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            &.deck-error {
              left: 45%;
              top: 65%;
            }
          }
        }
      }
    }

    .gs-privacy {
      margin-top: 8px;
      padding-bottom: 20px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #697077;

      a {
        color: #21a7e0;
        cursor: pointer;
      }
    }
    .gs-privacy-sub {
      margin-bottom: 2px;
    }
    .quick-access-toolbar {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  transform-origin: bottom;
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-4px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.template-selector-active {
  background: $white-default;
  box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3) !important;
  overflow: unset;
  .templates-list {
    max-height: 80vh !important;
    overflow: scroll;
  }
}
</style>
