<template>
  <div class="prompt-example-container">
    <div class="heading">
      {{ $t('generate.promptExamplesText') }}
      <!-- <div class="sub">Powered by ZenSense™ AI</div> -->
    </div>
    <v-tabs color="#21a7e0" height="57" fixed-tabs v-model="currentTab">
      <v-tab class="vtab" v-if="allowAgProTips">
        <b>{{ $t('generate.proTips.title') }}</b>
      </v-tab>
      <v-tab class="vtab">
        <b>{{ $t('build.steps[2]') }}</b>
      </v-tab>
      <v-tab class="vtab">
        <b>{{ $t('profile.prezentations') }}</b>
      </v-tab>
      <v-tab class="vtab">
        <b>{{ 'Context' }}</b>
      </v-tab>
      <v-tabs-items v-model="currentTab" class="slides-example-container">
        <v-tab-item class="pro-tip-container" v-if="allowAgProTips">
          <v-expansion-panels flat accordion>
            <v-expansion-panel v-for="(pTips, i) in proTips" :key="i">
              <v-expansion-panel-header class="pe-list__panel_header">
                <div class="pe-list__header">
                  <div>
                    {{ pTips.label }}
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pe-list__content">
                  <p class="pe-list__description" v-if="pTips.desc">
                    {{ pTips.desc }}
                  </p>
                  <div class="chip-container p-tips" v-if="pTips.isImageTab">
                    <v-chip-group
                      v-model="selectedImgChip"
                      mandatory
                      cols="1"
                      active-class="active-chip"
                    >
                      <v-chip
                        class="img-chip"
                        :ripple="false"
                        v-for="chip in pTips.imgChips"
                        :key="chip"
                      >
                        {{ chip }}
                      </v-chip>
                    </v-chip-group>
                    <div class="pe-list__img-container">
                      <img
                        class="pe-list__image"
                        v-if="pTips.imgsPath[selectedImgChip]"
                        :src="pTips.imgsPath[selectedImgChip]"
                      />
                    </div>
                  </div>

                  <template v-else-if="pTips.tips">
                    <ProTips
                      v-for="(tips, j) in pTips.tips"
                      :key="j"
                      :tips="tips"
                    />
                  </template>
                  <a @click="onViewExamples(pTips.viewExamplesTab)">
                    {{ $t('generate.proTips.viewExamples') }}
                  </a>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item>
          <div class="slides-example-content">
            <div class="chip-container">
              <v-chip-group
                v-model="selectedIndustry"
                mandatory
                cols="1"
                active-class="active-chip"
              >
                <v-chip
                  class="img-chip"
                  :ripple="false"
                  v-for="industry in industries"
                  :key="industry"
                  :value="industry"
                >
                  {{ industry }}
                </v-chip>
              </v-chip-group>
            </div>
            <div
              v-for="(slide, index) in slides[selectedIndustry]"
              class="slide-content"
              :key="slide.tip"
            >
              <div class="tip-container">
                <img src="/assets/img/generate/Tips-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.tip') }}</div>
                  <div class="tip-info">{{ slide.tip }}</div>
                </div>
              </div>
              <div v-for="(prompt, idx) in slide.prompts" :key="idx">
                <div class="tip-container">
                  <img src="/assets/img/generate/Examples-icon.svg" />
                  <div class="content">
                    <div class="tip-header">{{ $t('generate.example') }}</div>
                    <div class="tip-info" v-html="prompt.example"></div>
                  </div>
                </div>
                <img
                  :src="prompt.image"
                  class="tip-img"
                  @click="openDetailedView('slides', index, idx)"
                />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="slides-example-content">
            <div class="chip-container">
              <v-chip-group
                v-model="selectedIndustry"
                mandatory
                cols="1"
                active-class="active-chip"
              >
                <v-chip
                  class="img-chip"
                  :ripple="false"
                  v-for="industry in industries"
                  :key="industry"
                  :value="industry"
                >
                  {{ industry }}
                </v-chip>
              </v-chip-group>
            </div>
            <div
              v-for="(prez, ind) in prezentations[selectedIndustry]"
              class="slide-content"
              :key="prez.tip"
            >
              <div class="tip-container">
                <img src="/assets/img/generate/Tips-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.tip') }}</div>
                  <div class="tip-info">{{ prez.tip }}</div>
                </div>
              </div>
              <div v-for="(example, idx) in prez.examples" :key="idx">
                <div class="tip-container">
                  <img src="/assets/img/generate/Examples-icon.svg" />
                  <div class="content">
                    <div class="tip-header">{{ $t('generate.example') }}</div>
                    <div class="tip-info" v-html="example.example"></div>
                  </div>
                </div>
                <v-carousel
                  hide-delimiters
                  height="auto"
                  prev-icon="mdi-chevron-left"
                  next-icon="mdi-chevron-right"
                  light
                  class="tip-img"
                >
                  <v-carousel-item
                    v-for="(img, index) in example.images"
                    :key="index"
                    eager
                  >
                    <img
                      :src="img"
                      @click="openDetailedView('prezentation', ind, idx)"
                    />
                  </v-carousel-item>
                </v-carousel>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="slides-example-content">
            <div class="chip-container">
              <v-chip-group
                v-model="selectedIndustry"
                mandatory
                cols="1"
                active-class="active-chip"
              >
                <v-chip
                  class="img-chip"
                  :ripple="false"
                  v-for="industry in industries"
                  :key="industry"
                  :value="industry"
                >
                  {{ industry }}
                </v-chip>
              </v-chip-group>
            </div>
            <div
              v-for="context in context[selectedIndustry]"
              class="slide-content"
              :key="context.tip"
            >
              <div class="tip-container">
                <img src="/assets/img/generate/Tips-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.tip') }}</div>
                  <div class="tip-info">{{ context.tip }}</div>
                </div>
              </div>
              <div class="tip-container">
                <img src="/assets/img/generate/Examples-icon.svg" />
                <div class="content">
                  <div class="tip-header">{{ $t('generate.example') }}</div>
                  <div class="tip-info" v-html="context.prompt"></div>
                </div>
              </div>
              <div class="tip-container">
                <img src="/assets/img/generate/context.svg" />
                <div class="content">
                  <div class="tip-header">{{ 'Additional context' }}</div>
                  <div class="context-container">
                    <div
                      class="context"
                      v-for="(file, index) in context.files"
                      :key="index"
                    >
                      <img :src="file.icon" />
                      <div>{{ file.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <img
                :src="slide.image"
                class="tip-img"
                @click="openDetailedView('slides', index)"
              /> -->
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PromptExamplesDetailedView from './PromptExampleDetailedView.vue';
import ProTips from '../../common/SharedComponents/ProTips.vue';

export default {
  name: 'PromptExamples',
  components: {
    ProTips,
  },
  props: {
    openContext: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTab: 0,
      currentProTip: 0,
      tabKey: 0,
      selectedImgChip: 0,
      industries: ['Bio Pharma', 'Technology', 'Others'],
      selectedIndustry: 'Bio Pharma',
      proTips: [
        {
          label: this.$t('generate.proTips.tip1.label'),
          isImageTab: true,
          imgChips: ['Example 1', 'Example 2', 'Example 3', 'Example 4'],
          imgsPath: [
            '/assets/img/generate/pro-tip-1.svg',
            '/assets/img/generate/pro-tip-2.svg',
            '/assets/img/generate/pro-tip-3.svg',
            '/assets/img/generate/pro-tip-4.svg',
          ],
          viewExamplesTab: 2,
        },
        {
          label: this.$t('generate.proTips.tip2.label'),
          desc: this.$t('generate.proTips.tip2.desc'),
          tips: this.$t('generate.proTips.tip2.tips'),
          viewExamplesTab: 1,
        },
        {
          label: this.$t('generate.proTips.tip3.label'),
          desc: this.$t('generate.proTips.tip3.desc'),
          tips: this.$t('generate.proTips.tip3.tips'),
          viewExamplesTab: 3,
        },
        {
          label: this.$t('generate.proTips.tip4.label'),
          desc: this.$t('generate.proTips.tip4.desc'),
          tips: this.$t('generate.proTips.tip4.tips'),
          viewExamplesTab: 2,
        },
      ],
      slides: {
        'Bio Pharma': [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  "Create highlighting the ACME's cutting-edge R&D initiatives and their strategic importance in driving innovation and future drug development. Add an <strong>image of a research lab.</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  "Develop a slide with  <strong> 4 points </strong> on the NexCure's market growth strategy, showing key acquisitions, partnerships, and initiatives aimed at increasing global market share in biopharma.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_2.png',
              },
            ],
          },
          {
            tip: "Trying to generate slides in a specific template? Simply add the name of the preferred template followed by the keyword 'template'",
            prompts: [
              {
                example:
                  "Create a slide detailing the financial performance of the Pharmavex's key drug portfolio, emphasizing revenue growth, market penetration, and long-term profitability. Use the <strong>Prezent Starlight template</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Biopharma_3.png',
              },
            ],
          },
        ],
        Technology: [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  'Create a single slide showcasing the timeline for the platform launch of ACME Edge, an AI powered payment solution. Add an <strong>image of AI payment solution</strong>',
                image: '/assets/img/generate/prompt_examples/slides/Tech_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  "Create a slide highlighting <strong>5 points</strong> on Technova's advancements in cloud infrastructure and how it drives business scalability and global market expansion.",
                image: '/assets/img/generate/prompt_examples/slides/Tech_2.png',
              },
              {
                example:
                  'Design a slide outlining <strong>5 key elements,</strong> focusing on a telecom company’s employee engagement strategies and their impact on driving innovation and retaining top talent worldwide.',
                image: '/assets/img/generate/prompt_examples/slides/Tech_3.png',
              },
            ],
          },
          {
            tip: "Trying to generate slides in a specific template? Simply add the name of the preferred template followed by the keyword 'template'",
            prompts: [
              {
                example:
                  "Design a slide that highlight NanoByte's digital transformation journey demonstrating the company’s leadership in the tech space through strategic partnerships and its business implications for future growth and innovation. Using <strong>Prism Standard template</strong>",
                image: '/assets/img/generate/prompt_examples/slides/Tech_4.png',
              },
            ],
          },
        ],
        Others: [
          {
            tip: 'Have a particular visual in mind? Just describe the image in your prompt and see the magic happen',
            prompts: [
              {
                example:
                  "Create a slide focusing on WealthSafe Bank's customer retention initiatives, showcasing the impact of personalized financial services and mobile banking solutions on customer loyalty. Add an image on <strong>customer satisfaction</strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_1.png',
              },
            ],
          },
          {
            tip: 'You can customize slides based on the number of key messages. Just include node count in your prompt',
            prompts: [
              {
                example:
                  "Create a slide highlighting <strong>4 key points</strong> on OmniMed's supply chain optimization efforts and their impact on reducing costs and improving production timelines for critical medical devices.",
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_2.png',
              },
            ],
          },
          {
            tip: "Trying to generate slides in a specific template? Simply add the name of the preferred template followed by the keyword 'template'",
            prompts: [
              {
                example:
                  "Design a slide on InterNova's stock market performance, highlighting the company’s recent financial performance and its positive impact on shareholder value and global expansion plans. use the <strong>ACME Corporate template </strong>",
                image:
                  '/assets/img/generate/prompt_examples/slides/Others_3.png',
              },
            ],
          },
        ],
      },
      prezentations: {
        'Bio Pharma': [
          {
            tip: "Want to generate a presentation? Just include the keyword 'presentation' or specify the number of slides in your prompt",
            examples: [
              {
                example:
                  "Create a <strong>5 slide presentation</strong> highlighting our company performance in the bio pharma space. Mention the key strategic initiatives, and risk assessments. Outline long-term goals and any critical updates for the board's consideration.",
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg1/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg1/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg1/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg1/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg1/Slide5.png',
                ],
              },
            ],
          },
          {
            tip: 'Have specific topics to add to your presentation? Simply include them in your prompt',
            examples: [
              {
                example:
                  'Create a 7 slide Presentation on the current status and future plans for our clinical trials, including <strong>timelines for patient recruitment, site selection, and key milestones.</strong> Emphasize any <strong>risks or challenges that could impact trial success.</strong>',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide5.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide6.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg2/Slide7.png',
                ],
              },
            ],
          },
          {
            tip: 'You can generate a presentation as per your meeting duration. Just include the duration in your prompt ',
            examples: [
              {
                example:
                  'Create a <strong>15 minute</strong> presentation on a pharmaceutical e-learning platform for healthcare professionals. Include topics like courses offered, accreditation and user experience',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg3/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg3/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg3/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg3/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/BioPharma/Eg3/Slide5.png',
                ],
              },
            ],
          },
        ],
        Technology: [
          {
            tip: "Want to generate a presentation? Just include the keyword 'presentation' or specify the number of slides in your prompt",
            examples: [
              {
                example:
                  'Develop a <strong>5 slide presentation</strong> for cybersecurity firm DataGuard’s global expansion, highlighting market opportunities, key strengths, expansion strategy, risk management, and projected financial outcomes.',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg1/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg1/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg1/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg1/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg1/Slide5.png',
                ],
              },
            ],
          },
          {
            tip: 'Have specific topics to add to your presentation? Simply include them in your prompt',
            examples: [
              {
                example:
                  'Create a deck that demonstrates how InnovateX, a leading e-commerce company, is using <strong>artificial intelligence to improve customer experience.</strong> Highlight <strong>key AI technologies such as machine learning algorithms, chatbots, and personalized recommendation systems.</strong>',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg2/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg2/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg2/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg2/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg2/Slide5.png',
                ],
              },
              {
                example:
                  'Create a 6-slide presentation on the implementation of OpenRAN technology in TeleComCo’s network expansion strategy. Highlight how <strong>OpenRAN reduces infrastructure costs, increases vendor diversity, and accelerates 5G rollout</strong>',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide5.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg3/Slide6.png',
                ],
              },
            ],
          },
          {
            tip: 'You can generate a presentation as per your meeting duration. Just include the duration in your prompt ',
            examples: [
              {
                example:
                  "Create a <strong>15 minute</strong> detailed presentation outlining TechWave's strategy to transition 70% of its IT infrastructure to the cloud within the next two years.",
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg4/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg4/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg4/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg4/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Tech/Eg4/Slide5.png',
                ],
              },
            ],
          },
        ],
        Others: [
          {
            tip: "Want to generate a presentation? Just include the keyword 'presentation' or specify the number of slides in your prompt",
            examples: [
              {
                example:
                  'Create <strong>3 slides</strong> for DataSphere Solutions, a consulting firm, showcasing its digital transformation strategy.',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg1/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg1/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg1/Slide3.png',
                ],
              },
            ],
          },
          {
            tip: 'Have specific topics to add to your presentation? Simply include them in your prompt',
            examples: [
              {
                example:
                  'Create a 20 minute presentation outlining Citadel Wealth’s strategy to implement a biometric authentication system for high-net-worth clients. Include <strong>potential cybersecurity risks, user adoption rates, and improvements in fraud prevention</strong>',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide5.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg2/Slide6.png',
                ],
              },
            ],
          },
          {
            tip: 'You can generate a presentation as per your meeting duration. Just include the duration in your prompt ',
            examples: [
              {
                example:
                  'Create a <strong> 20 minute</strong> presentation on an employee upskilling platform designed for manufacturing operators in the healthcare industry. Include course offerings, accreditation, and how the platform enhances workforce efficiency and compliance.',
                images: [
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide1.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide2.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide3.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide4.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide5.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide6.png',
                  '/assets/img/generate/prompt_examples/prezentations/Others/Eg3/Slide7.png',
                ],
              },
            ],
          },
        ],
      },
      context: {
        'Bio Pharma': [
          {
            tip: 'Need to provide more context? Just upload relevant documents and mention the key sections.  Auto Generator will create slides that address your particular topics',
            prompt:
              'Create a 5 slide presentation stating key strategic initiatives for ACME corp. Extract information on expansion into emerging markets, R&D investments, and partnerships that drive innovation in biologics and specialty medicines.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Acme Performance Report',
              },
            ],
          },
          {
            tip: "Multiply the impact! Upload multiple files to enrich your presentation's context. Auto Generator will blend insights from all files, ensuring your slides are packed with depth and information.",
            prompt:
              'Create a 5 slide presentation to outline the current regulatory landscape in the bio pharma sector. Focus on the company’s strategies to navigate compliance challenges, regulatory approvals for new treatments, and risk mitigation in terms of patent expirations and legal hurdles.',
            files: [
              {
                icon: '/assets/img/generate/DOC.svg',
                name: 'Compliance Risk Report',
              },
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Company Annual Report ',
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the given document',
            files: [
              {
                icon: '/assets/img/generate/PPT.svg',
                name: 'US Bio Pharma landscape',
              },
              {
                icon: '/assets/img/generate/link.svg',
                name: 'Weblink covering FDA Regulation Updates',
              },
            ],
          },
        ],
        Technology: [
          {
            tip: 'Need to provide more context? Just upload relevant documents and mention the key sections.  Auto Generator will create slides that address your particular topics',
            prompt:
              'Use the attached document to build a 5 slide deck analyzing the Quantum AI company’s market position in the Software-as-a-Service (SaaS) industry. Highlight competitive advantages, market share growth, and strategies to differentiate from competitors through innovation and customer-centric solutions.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Document on Quantum AI growth plan',
              },
            ],
          },
          {
            tip: "Multiply the impact! Upload multiple files to enrich your presentation's context. Auto Generator will blend insights from all files, ensuring your slides are packed with depth and information.",
            prompt:
              'Create a slide highlighting Neo AI’s strategic efforts in optimizing data centers for energy efficiency, responsible sourcing of hardware components, and reducing environmental impact in their cloud and hardware services, focusing on how these initiatives drive operational efficiency and long-term cost savings.',
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: "PDF on Neo AI's Initiative for energy-efficient systems",
              },
              {
                icon: '/assets/img/generate/PPT.svg',
                name: 'Deck on efforts and activities undertaken to improve operational efficiency',
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the attached deck in 7 slides',
            files: [
              {
                icon: '/assets/img/generate/PPT.svg',
                name: "30 slide deck on ACME's Tech Vision 2030",
              },
            ],
          },
        ],
        Others: [
          {
            tip: 'Need to provide more context? Just upload relevant documents and mention the key sections.  Auto Generator will create slides that address your particular topics',
            prompt:
              "Create a 12 slide presentation on ACME MediTronic's strategic initiatives in expanding into international markets with their latest line of Blood Glucose monitors",
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: 'Document on ACME’s Product roadmap',
              },
            ],
          },
          {
            tip: "Multiply the impact! Upload multiple files to enrich your presentation's context. Auto Generator will blend insights from all files, ensuring your slides are packed with depth and information.",
            prompt:
              "Create a presentation focused on the GreenField's financial performance and future investment strategy. Extract key data on revenue growth, operating margins, and capital allocation toward strategic investments in R&D, new product launches, and acquisitions.",
            files: [
              {
                icon: '/assets/img/generate/PDF.svg',
                name: "GreenField's 2024-2025 Financial outlook report",
              },
              {
                icon: '/assets/img/generate/PPT.svg',
                name: 'Deck of Long Term Research Pipeline',
              },
            ],
          },
          {
            tip: 'Need to create a concise overview of a lengthy report or a deck? Just ask Auto Generator to summarize it for you.',
            prompt: 'Summarize the report in 5 slides',
            files: [
              {
                icon: '/assets/img/generate/DOC.svg',
                name: 'A 20-page report on PureWater Outreach Programs',
              },
            ],
          },
        ],
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters('users', ['allowAgProTips']),
  },
  methods: {
    openDetailedView(type, index, exampleIndex) {
      console.log(type, index);
      this.$modal.show(
        PromptExamplesDetailedView,
        {
          type,
          data:
            type === 'slides'
              ? this.slides[this.selectedIndustry][index].prompts[exampleIndex]
              : this.prezentations[this.selectedIndustry][index].examples[
                  exampleIndex
                ],
        },
        {
          name: 'PromptExamplesDetailedView',
          width: type === 'slides' ? '830px' : '1074px',
          height: 'auto',
          styles: {
            'max-height': '90%',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    onViewExamples(tab) {
      this.currentTab = tab;
    },
  },
  mounted() {
    if (this.openContext) {
      if (this.allowAgProTips) {
        this.currentTab = 3;
      } else {
        this.currentTab = 2;
      }
    }
  },
};
</script>
<style scoped lang="scss">
.prompt-example-container {
  padding: 20px 50px 20px 20px;
  width: 550px;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .v-tab {
    text-transform: none !important;
    color: black;
    font-weight: 300;
    font-family: 'Lato';
    font-size: 1.1rem;
    letter-spacing: normal;
    padding: 16px 8px 16px 8px;
  }

  .slide-content {
    border-bottom: 0.5px solid #c1c7cd;
  }

  .tip-container {
    display: flex;
    margin-top: 20px;
    gap: 20px;
    padding: 10px 10px 10px 0px;
    align-items: flex-start;

    .content {
      position: relative;
      top: 8px;

      .tip-header {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #075689;
        margin-bottom: 10px;
      }

      .tip-info {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
      }
    }
  }

  .tip-img {
    border-radius: 6px;
    margin: 16px 0;
    margin-left: 60px;
    margin-bottom: 40px;
    width: calc(100% - 60px);
    border: 0.5px solid #c1c7cd;
    cursor: pointer;
    ::v-deep .v-icon {
      color: white;
    }
  }

  .context-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
    margin-top: 20px;

    .context {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .slides-example-container {
    height: calc(100vh - 215px);
    overflow: auto;
    padding-right: 10px;
  }
}
@-moz-document url-prefix() {
  .slide-content {
    padding-right: 10px;
  }
}
.pe-list {
  &__panel_header {
    padding: 16px 0;
    min-height: 64px;
  }
  &__header {
    display: flex;
    font-weight: 600;
    color: #075689 !important;
    align-items: center;
    font-size: 16px;
  }
  &__description {
    text-align: left;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    color: #212121;
  }
}
.pro-tip-container {
  margin-top: 10px;
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
    margin-bottom: 10px;
  }
}

.chip-container {
  margin-bottom: 12px;

  &.p-tips {
    ::v-deep .v-slide-group__content {
      justify-content: space-between;
      margin-top: 0;
    }
  }
  ::v-deep .v-slide-group__content {
    gap: 12px;
    margin-top: 28px;
    padding-right: 10px;
    padding-top: 0;
    .active-chip {
      background: #e1f5fb !important;
      border: 1px solid transparent !important;
      &::before {
        opacity: 0;
      }
    }
    v-chip,
    .v-chip--outlined,
    .v-chip.v-chip {
      background: none;
      border: 1px solid rgba(105, 112, 119, 1);
      margin-top: 0;
    }
  }
  .img-chip {
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    border: 1px solid #697077;
    color: #343a3f;
    border-radius: 8px;
  }
  img {
    width: 100%;
  }
}
</style>
