<template>
  <div class="replace-visual">
    <div class="replace-visual-title">
      {{ $t('generate.replaceVisual') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div v-if="isLoading" class="loading-spinner">
      <v-progress-circular
        :size="50"
        :width="2"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="error" class="no-image">
      <img
        :src="'/assets/img/generate/slide-with-no-visuals.svg'"
        alt="image"
        contain
      />
      <div class="desc">
        <div>{{ $t('generate.currentSlideHaveNoVisuals') }}</div>
        <div>{{ $t('generate.toAddVisualUseDifferentLayout') }}</div>
      </div>
      <v-btn
        elevation="2"
        rounded
        color="primary"
        class="primary-btn"
        @click="changeLayout"
      >
        {{ $t('generate.changeLayout') }}
      </v-btn>
    </div>
    <template v-else>
      <div
        class="replace-visual-verbose"
        :style="{
          paddingTop:
            origin === 'Synthesis' || origin === 'Redesign' ? '8px' : '16px',
        }"
      >
        {{ $t('replaceImage.selectImageReplace') }}
      </div>
      <div
        justify="center"
        align="center"
        :class="`select-image my-1 ${
          isConvertingInProgress || isLocalImageUploading ? 'item-disabled' : ''
        } `"
        v-if="!isLoading && filteredImages && filteredImages.length > 0"
      >
        <v-slide-group v-model="model" show-arrows="always">
          <v-slide-item
            v-for="item in filteredImages"
            :key="item.replace_ref"
            v-slot="{ toggle }"
            :class="`${
              item.imageIndex ===
              (currentSelectedSlideImageData &&
                currentSelectedSlideImageData.imageIndex)
                ? 'selected'
                : ''
            }`"
          >
            <v-img
              v-if="!item.invalid"
              class="select-image__item ma-2"
              max-height="80"
              max-width="100"
              height="80"
              contain
              :src="item.imgUrl"
              @click="onImageSelected(item, toggle)"
              lazy-src="/assets/img/slides/placeholder-slide.svg"
            ></v-img>
          </v-slide-item>
        </v-slide-group>
      </div>
      <ImageLibrary
        @imageSelected="onReplaceImage($event)"
        :extractedImages="extractedImages"
        :currentSelectedSlideImageData="currentSelectedSlideImageData"
        :isConvertingInProgress="isConvertingInProgress"
        :key="imageLibraryComponentKey"
        :slideData="selectedSlide"
        isGenerate
        :origin="origin"
        v-on="$listeners"
      />
    </template>
    <ErrorDialog
      :show="isImageFound"
      :on-primary-click="onErrorDialogOk"
      :primary-btn-text="$t('common.ok')"
      :text="$t(errorMessageLoadingImageReplaceData)"
      :onClose="onErrorDialogClose"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { calyrexConfig } from '../../../../runtimeConfig';
import { extractImage } from '../../../../utils/replace-image-api-helper';
import ImageLibrary from '../../SlideImageReplacement/ImageLibrary.vue';
import ImageNotSuitable from '../../SlideImageReplacement/ImageNotSuitable.vue';
import ErrorDialog from '../../SlideImageReplacement/ErrorDialog.vue';
import {
  IMAGE_UPLOAD_FAILED,
  LOCAL_IMAGE_UPLOADED,
  LOCAL_IMAGE_UPLOADING,
} from '../../SlideImageReplacement/constants';
import EventBus from '../../../common/event-bus';
import { getFileURLDownload } from '@/utils/calyrex-test';

export default {
  data() {
    return {
      model: 0,
      isConvertingInProgress: false,
      isLocalImageUploading: false,
      filteredImages: null,
      isErrorLoadingImageReplaceData: false,
      isImageFound: false,
      errorMessageLoadingImageReplaceData:
        'Sorry. We are temporarily unable to replace the image. Please try a different slide.',
      notImages: ['image/x-wmf', 'image/x-emf'],
      currentSelectedSlideImageData: null,
      isLoading: false,
      imageLibraryComponentKey: 1,
      imageToReplace: null,
      selectedImageSeqId: null,
    };
  },
  props: {
    slide: {
      type: Object,
      default: () => null,
    },
    origin: {
      type: String,
      default: 'generate',
    },
    extractedImages: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    async slide(newVal, oldVal) {
      if (oldVal.id !== newVal.id) {
        this.isErrorLoadingImageReplaceData = false;
        this.isImageFound = false;
        await this.loadImageReplacementData();
      }
    },
  },
  beforeDestroy() {
    EventBus.$off('IMAGE_UPLOAD_FAILED');
  },
  computed: {
    ...mapState('users', ['currentUser']),
    error() {
      return (
        !this.slide ||
        this.slide.error ||
        !this.selectedSlide ||
        !this.selectedSlide.prefs ||
        this.selectedSlide.prefs.has_image === 'no' ||
        this.isErrorLoadingImageReplaceData ||
        this.isImageFound
      );
    },
    selectedSlide() {
      return this.slide ? this.slide.slideDetails : null;
    },
    getS3Bucket() {
      const slidePath = this.slide.slide_path;
      if (slidePath?.startsWith('/newImageReplaceOutput/output/')) {
        return calyrexConfig.calyrex_bucket_name;
      }
      return process.env.VUE_APP_MVP_ASSETS_BUCKET;
    },
    getS3Path() {
      const { replacedSlideData, filename } = this.selectedSlide;
      return replacedSlideData?.s3_path || filename;
    },
  },
  methods: {
    ...mapActions('replaceImageStore', ['makeDirty']),
    changeLayout() {
      this.$emit('changePanel', 'changeLayout');
      // this.setRightPanelComponent('changeLayout');
    },
    onErrorDialogOk() {
      this.isImageFound = false;
      this.errorMessageLoadingImageReplaceData = '';
      this.$emit('toggleReplaceImage', {
        type: 'replaceVisuals',
        path: this.slide.slide_path,
        thumbnail: this.slide.slide_thumbnail,
        landscape: this.slide.landscape,
      });
    },
    async onReplaceImage(imageToReplace) {
      try {
        this.$emit('replaceStart', {
          imageToReplace,
          currentSelectedSlideImageData: this.currentSelectedSlideImageData,
        });
        this.inProgSlideID = this.slide.id;
        this.isConvertingInProgress = true;
        this.isConvertingInProgress = false;
      } catch (e) {
        console.log('TriggerReplaceImage error: ', e);
      }
    },
    onImageSelected(slideImage, toggle) {
      toggle();
      this.currentSelectedSlideImageData = {
        ...slideImage,
        imageName: this.selectedSlide.name || this.selectedSlide.title,
        // resizeData: this.getResizeImageData(slideImage),
      };
      this.selectedImageSeqId = slideImage.seq_id;
      this.imageLibraryComponentKey++;
    },
    async getSignedFileUrlFromCalyrex(filePath) {
      return await getFileURLDownload(
        this.currentUser.user.cognitoID,
        filePath,
        this.currentUser.userIp,
      );
    },
    async loadImagesInDeck(imageDetails) {
      for (const image of imageDetails) {
        const path = `${image.s3_path}`;
        image.imgUrl = null; // Need this to allow <v-img to work
        const imgUrl = await this.getSignedFileUrlFromCalyrex(path);
        image.imgUrl = imgUrl;
        if (
          !image.invalid &&
          (image.seq_id === this.selectedImageSeqId || image.seq_id === 0)
        ) {
          this.setCurrentSelectedSlideImageData(image);
        }
      }

      this.isErrorLoadingImageReplaceData = imageDetails.length === 0;
      if (this.isErrorLoadingImageReplaceData) {
        this.errorMessageLoadingImageReplaceData =
          'Sorry. No image found on the slide.';
      }

      if (!this.currentSelectedSlideImageData && imageDetails.length) {
        this.setCurrentSelectedSlideImageData(imageDetails[0]);
      }
    },
    setCurrentSelectedSlideImageData(image) {
      this.currentSelectedSlideImageData = {
        ...image,
        imageName: this.selectedSlide.name || this.selectedSlide.title,
      };
    },
    async getDeckDetailsData() {
      const body = {
        s3_bucket: this.getS3Bucket,
        s3_path: this.slide.slide_path,
        force_update: false,
      };
      const response = await extractImage(body);

      if (response?.images && response?.images.length > 0) {
        return response.images;
      }
      console.log('Failed to get deck details', response);
      this.isErrorLoadingImageReplaceData = true;
      this.errorMessageLoadingImageReplaceData = `Replace image timed out or couldn't find any images on slide. Try again.`;
      return null;
    },
    selectImage(imageToReplace, currImage, slideID) {
      if (this.slide.id === slideID) {
        this.currentSelectedSlideImageData = null;

        this.filteredImages = this.filteredImages.map((image) => {
          if (
            image !== undefined &&
            image.imageIndex === currImage.imageIndex
          ) {
            image.imgUrl = imageToReplace.url;
            this.currentSelectedSlideImageData = image;
          }

          return image;
        });
      }
    },
    onErrorDialogClose() {
      this.isErrorLoadingImageReplaceData = false;
    },
    async loadImageReplacementData() {
      if (this.error) {
        return;
      }
      this.isLoading = true;
      this.imageToReplace = null;
      this.currentSelectedSlideImageData = null;
      this.filteredImages = await this.getDeckDetailsData();
      if (this.filteredImages) {
        await this.loadImagesInDeck(this.filteredImages);
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    EventBus.$on(LOCAL_IMAGE_UPLOADING, () => {
      this.isLocalImageUploading = true;
    });
    EventBus.$on(LOCAL_IMAGE_UPLOADED, () => {
      this.isLocalImageUploading = false;
    });
    EventBus.$on(IMAGE_UPLOAD_FAILED, (subTitle) => {
      this.$emit('error', { id: this.inProgSlideID });
      this.isLocalImageUploading = false;
      this.$modal.show(
        ImageNotSuitable,
        {
          subTitle,
        },
        {
          name: 'ImageNotSuitable',
          width: '564',
          height: '200px',
        },
      );
    });
    await this.loadImageReplacementData();
  },
  components: { ImageLibrary, ErrorDialog },
};
</script>

<style lang="scss" scoped>
.replace-visual {
  height: 100%;
  padding: 20px;

  .loading-spinner {
    height: calc(100% - 42px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-image {
    display: flex;
    gap: 24px;
    align-items: center;
    height: calc(100% - 42px);
    flex-direction: column;
    margin-top: 36px;

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #000000;
    }

    .primary-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
  }
  .replace-visual-title {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .replace-visual-verbose {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
  }

  .select-image {
    align-items: center;
    display: flex;
    padding-top: 2px;
    max-width: 100%;

    .select-image__item {
      border: solid $light-gray 1px;
      border-radius: 8px;
      box-shadow: $item-box-shadow;
      min-width: 100px;

      &:hover {
        box-shadow: $item-box-shadow-hover;
        cursor: pointer;
      }
    }

    .selected {
      border: 2px solid $zen-blue-default;
    }
  }
}
</style>
