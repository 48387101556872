/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';
import {
  TC_COMPLY_API_SERVICES,
  getLoggedInUserToken,
} from '../../../../utils/api';
import {
  checkBulkStatus,
  fetchRecomendedTemplateImages,
  fetchSlidesDataByUploadId,
  fetchSlidesListByUploadId,
  poll,
} from '../../../../utils/api-helper';

export const ERROR_CODES = {
  ComplianceCheckFailure: 'complyCheckFailed',
  FileSizeFailure: 'fileSizeExceeded',
  FileUploadFailure: 'fileUploadFailed',
};

export async function fetchSuggestionDataFromS3(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const text = await blob.text();
    const data = JSON.parse(text);
    return data;
  } catch (error) {
    console.error('Error in downloading or reading the file:', error);
    throw error;
  }
}

export function getMatchingSuggestionMapping(slide, suggestion) {
  const { category, categorySubIndex, mappingIndex } = suggestion;
  const byCategory =
    slide.suggestions.data.slide_data.format_properties.format_suggestions[
      category
    ];

  if (categorySubIndex !== undefined && byCategory[categorySubIndex]) {
    const bySubCategory = byCategory[categorySubIndex];
    if (mappingIndex !== undefined && bySubCategory?.mapping[mappingIndex]) {
      return bySubCategory?.mapping[mappingIndex];
    }
  }
  return null;
}

/**
 * Runs promises in batches and returns the list of responses.
 *
 * @param {Array<Promises>} promiseFunctions - Array of promises.
 * @param {number} batchSize - The size of each batch.
 * @returns {Promise<Array>} - A promise that resolves to an array of responses.
 */
export async function runBatches(promiseFunctions, batchSize) {
  const results = [];
  for (let i = 0; i < promiseFunctions.length; i += batchSize) {
    const batch = promiseFunctions.slice(i, i + batchSize).map((fn) => fn());
    const batchResults = await Promise.all(batch);
    results.push(...batchResults);
  }
  console.log(results, 'results');
  return results;
}

/**
 * Calls upload deck flow - TC
 *
 * @param {Object} filePayload - payload.
 * @param {string} filePayload.s3 - s3 bucket of uploaded file.
 * @param {string} filePayload.filepath - filepath.
 * @param {string} filePayload.filename - filename.
 * @param {string} filePayload.sizeKb - file size.
 * @returns {Object} The response.
 * @returns {string} response.data.upload_id - The unique ID of the uploaded file flow.
 */
export const uploadAsyncDeck = async (filePayload) =>
  await TC_COMPLY_API_SERVICES.post(
    '/comply/user/upload-deck-async',
    filePayload,
  );

export const fetchRecomendedTemplatesByUploadId = async (uploadId) =>
  await TC_COMPLY_API_SERVICES.get(
    `/comply/user/fetch-recommended-templates?upload_id=${uploadId}`,
  );

export const convertTemplate = async (convertPayload) =>
  await TC_COMPLY_API_SERVICES.post(
    `/comply/user/convert-template`,
    convertPayload,
  );

export const checkCallbackCompletion = (callbacks, signal) =>
  new Promise((resolve, reject) => {
    try {
      const ids = [...callbacks];
      const operation = () => checkBulkStatus(ids);
      const validateResponse = () => ids.length === 0;
      const callback = (response) => {
        ids.forEach((id, index) => {
          if (
            (response[id] && response[id].workflow_status === 'success') ||
            response[id].workflow_status === 'failed'
          ) {
            ids.splice(index, 1);
          }
        });
      };

      poll(operation, validateResponse, 3000, 150, signal, callback)
        .then((res) => {
          console.log(res);
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });

export const initialComplianceCheck = async (complyPayload, signal) => {
  const response = await TC_COMPLY_API_SERVICES.post(
    `/comply/user/initial-compliance`,
    complyPayload,
  );
  await checkCallbackCompletion(response.data.callbacks, signal);
  return response;
};

export const fetchSlidesData = (uploadId, signal, returnOnlyScore = false) =>
  new Promise((resolve, reject) => {
    const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
    const uuid = uuidv4();
    const operation = () => fetchSlidesDataByUploadId(uploadId);
    const validateResponse = (response) =>
      response.status === 'error' ||
      (response.status === 'success' &&
        response.data &&
        response.data.slide_images &&
        Object.keys(response.data.slide_images).length > 0);

    getLoggedInUserToken().then((token) => {
      poll(operation, validateResponse, 3000, 200, signal)
        .then(async (response) => {
          if (response.status === 'error') {
            reject(response);
          }
          if (!returnOnlyScore) {
            const promises = [];
            const slides = Object.values(response.data.slide_images);
            for (let i = 0; i < slides.length; i++) {
              const prom = () =>
                new Promise((res, rej) => {
                  try {
                    const slide = slides[i];
                    const slideThumbnailUrl = `${awsAssetsDistribution}/${slide.png.s3_path}?accesskey=${token}&uuid=${uuid}`;
                    slide.png = { ...slide.png, thumbnail: slideThumbnailUrl };
                    const pptUrl = `${awsAssetsDistribution}/${slide.pptx.s3_path}?accesskey=${token}&uuid=${uuid}`;
                    slide.pptx = { ...slide.pptx, thumbnail: pptUrl };
                    if (
                      slide.suggestions &&
                      Object.keys(slide.suggestions).length > 0
                    ) {
                      const suggestionJsonFileUrl = `${awsAssetsDistribution}/${slide.suggestions.filepath}?accesskey=${token}&uuid=${uuid}`;
                      fetchSuggestionDataFromS3(suggestionJsonFileUrl).then(
                        (data) => {
                          slide.suggestions.data = data;
                          res(i);
                        },
                      );
                    }
                  } catch (err) {
                    console.log(err);
                    rej(err);
                  }
                });
              promises.push(prom);
            }
            await runBatches(promises, 5);
            resolve({ ...response.data, status: response.status });
          } else {
            // eslint-disable-next-line camelcase
            const { slide_images, ...scores } = response.data;
            resolve(scores);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          throw error;
        });
    });
  });

export const fetchSlideImages = (slidesResp) =>
  new Promise((resolve, reject) => {
    try {
      const slidesData = {
        slide_images: [],
      };
      getLoggedInUserToken().then((token) => {
        const awsAssetsDistribution =
          process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
        const uuid = uuidv4();
        const slideImages = [...slidesResp];
        // fetching the images and pptx files and re-structuring the API response as list of objects;
        for (const key in slideImages) {
          if (Object.hasOwnProperty.call(slideImages, key)) {
            let slideInfo = {};
            const pngImageInfo = slideImages[key].thumbnail_jpg;
            slideImages[key].thumbnail_jpg = {
              ...pngImageInfo,
              thumbnail: `${awsAssetsDistribution}/${pngImageInfo.s3_path}?accesskey=${token}&uuid=${uuid}`,
            };
            slideInfo = { key, ...slideImages[key] };
            slidesData.slide_images.push(slideInfo);
          }
        }
        resolve(slidesData);
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const getSlideImagesTC = (uploadId, signal) =>
  new Promise((resolve, reject) => {
    const operation = () => fetchSlidesListByUploadId(uploadId);
    const validateResponse = (response) =>
      Object.keys(response.outputs).length > 0 &&
      response.outputs['U-Thumbnails'] &&
      response.outputs['U-Thumbnails'].status === 'success';

    poll(operation, validateResponse, 3000, 150, signal)
      .then(async (response) => {
        // console.log(response);
        const slidesWithImages = await fetchSlideImages(
          response.outputs['U-Thumbnails'].data,
        );
        console.log(slidesWithImages);
        resolve(slidesWithImages);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

export const getTemplatesListTC = (uploadId, signal) =>
  new Promise((resolve, reject) => {
    const operation = () => fetchSlidesListByUploadId(uploadId);
    const validateResponse = (response) =>
      Object.keys(response.outputs).length > 0 &&
      response.outputs['Recommended-Templates'] &&
      response.outputs['Recommended-Templates'].status === 'success';

    poll(operation, validateResponse, 3000, 150, signal)
      .then(async (response) => {
        // console.log(response);
        const templatesWithImages = await fetchRecomendedTemplateImages(
          response.outputs['Recommended-Templates'].data[0],
          [],
          'comply',
        );
        console.log(templatesWithImages);
        resolve(templatesWithImages);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

export const fetchTCTemplates = (uploadId, signal) =>
  new Promise((resolve, reject) => {
    const operation = () => fetchRecomendedTemplatesByUploadId(uploadId);
    const validateResponse = (response) =>
      response.status === 'success' &&
      response.data &&
      Object.keys(response.data).length > 0;

    poll(operation, validateResponse, 3000, 150, signal)
      .then(async (response) => {
        console.log(response);
        const templatesWithImages = await fetchRecomendedTemplateImages(
          response.data,
          [],
          'comply',
        );
        resolve(templatesWithImages);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

const verifySlideProperites = (slidesListResp, userSelectedSlides) => {
  let verifiedSlidesCount = 0;
  userSelectedSlides.forEach((slide) => {
    const updatedSlide = slidesListResp.find((s) => s.index === slide.index);
    if (!!updatedSlide && updatedSlide.pptx && updatedSlide.pdf) {
      verifiedSlidesCount += 1;
    }
  });
  // commit('SET_SELECTED_SLIDES', userSelectedSlides);
  return userSelectedSlides.length === verifiedSlidesCount;
};

export const fetchSplitPPTx = (uploadId, selectedSlides, signal) =>
  new Promise((resolve, reject) => {
    try {
      const operation = () => fetchSlidesListByUploadId(uploadId);
      const validateResponse = (response) =>
        Object.keys(response.outputs).length > 0 &&
        response.outputs['U-SplitPPTX'] &&
        response.outputs['U-SplitPPTX'].status === 'success' &&
        verifySlideProperites(
          response.outputs['U-SplitPPTX'].data,
          selectedSlides,
        );

      poll(operation, validateResponse, 3000, 150, signal)
        .then(async (response) => {
          console.log(response);
          //   const verificationStatus = await dispatch(
          //     'verifySlideProperites',
          //     response.outputs['U-SplitPPTX'].data,
          //   );
          resolve(response.outputs['U-SplitPPTX'].data);
        })
        .catch((error) => {
          console.log(error);
          //   commit('RESET_STATE');
          reject(error);
        });
    } catch (err) {
      console.log(err);
    }
  });

const fetchSlideImagesFromS3 = (slidesResp) =>
  new Promise((resolve, reject) => {
    try {
      let slidesData = {
        slide_images: [],
      };
      getLoggedInUserToken().then((token) => {
        const awsAssetsDistribution =
          process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
        const uuid = uuidv4();

        const { slide_images, ...others } = slidesResp;
        // fetching the images and pptx files and re-structuring the API response as list of objects;
        // eslint-disable-next-line guard-for-in
        for (const key in slide_images) {
          if (Object.hasOwnProperty.call(slide_images, key)) {
            let slideInfo = {};
            const pngImageInfo = slide_images[key].png;
            slide_images[key].png = {
              ...pngImageInfo,
              thumbnail: `${awsAssetsDistribution}/${pngImageInfo.s3_path}?accesskey=${token}&uuid=${uuid}`,
            };
            const pptxImageInfo = slide_images[key].pptx;
            slide_images[key].pptx = {
              ...pptxImageInfo,
              thumbnail: `${awsAssetsDistribution}/${pptxImageInfo.s3_path}?accesskey=${token}&uuid=${uuid}`,
            };
            slideInfo = { key, ...slide_images[key] };
            slidesData.slide_images.push(slideInfo);
          }
        }
        slidesData = { ...slidesData, ...others };
        resolve(slidesData);
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const fetchSlidesInfo = (uploadId, signal) =>
  new Promise((resolve, reject) => {
    const operation = () => fetchSlidesDataByUploadId(uploadId);
    const validateResponse = (response) =>
      response.status === 'success' &&
      response.data &&
      response.data.slide_images &&
      Object.keys(response.data.slide_images).length > 0;

    poll(operation, validateResponse, 3000, 150, signal)
      .then(async (response) => {
        try {
          const slidesWithImages = await fetchSlideImagesFromS3(response.data);
          resolve({ ...slidesWithImages, status: response.status });
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });

export const reviewDetails = async (convertPayload, signal) => {
  const response = await convertTemplate(convertPayload);
  await checkCallbackCompletion(response.data.callbacks, signal);
  const slidesData = await fetchSlidesData(
    convertPayload.upload_id,
    signal,
    false,
  );
  return slidesData;
};

export const getSlideSource = (type) => {
  switch (type) {
    case 'isComply':
      return 'comply';

    case 'isUserUploaded':
    case 'isUploaded':
      return 'uploaded';

    case 'isGenerated':
      return 'generated';

    case 'isRedesign':
      return 'redesign';

    case 'isSynthesis':
      return 'synthesis';

    default:
      return '';
  }
};

export const getSlideType = (type) => ({
  isRedesign: false,
  isSynthesis: false,
  isComply: false,
  isGenerated: false,
  isOPUploaded: false,
  isUserUploaded: false,
  isUploaded: false,
  isAnonymized: false,
  isIOCUploaded: false,
  source: getSlideSource(type),
  [type]: true,
});
